/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE,
  DELETE_MANY,
  fetchUtils,
} from 'react-admin';
import _ from 'lodash';
import { stringify } from 'query-string';

import { API_URL_ADMIN } from '../constant/url';
import axios from 'axios'
import { config } from '../constant/url';

/**
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */

export const tkhttpClient = (url, options = {}) => {
  const sendOptions = { ...options };
  if (!sendOptions.headers) {
    sendOptions.headers = new Headers({ Accept: 'application/json' });
  }
  // sendOptions.mode = 'no-cors';
  sendOptions.credentials = 'include';
  return fetchUtils.fetchJson(url, sendOptions);
};

const customDataRequest = (resource, params) => {
  switch (resource) {
    case 'honoredStudents': {
      const { data } = params;
      const listPrize = data.prize.map(el => el.prize);
      data.prize = listPrize;
      return JSON.stringify(data);
    }
    case 'contests': {
      const { data } = params;
      const convertStartDate = Number(new Date(data.startDate));
      const convertEndDate = Number(new Date(data.endDate));
      if (convertStartDate < convertEndDate) {
        data.startDate = convertStartDate;
        data.endDate = convertEndDate;
        delete data["id"]
        delete data["_id"]
        delete data["createdAt"]
        delete data["updatedAt"]
        return JSON.stringify(data);
      } else {
        throw new Error(`start date not exceed end date`);
      }
    }
    case 'submissions': {
      const { data } = params;
      if (data.editUsers && data.editUsers.length) {
        data.users = data.editUsers;
      }
      return JSON.stringify(data);
    }

    default: {
      return JSON.stringify(params.data);
    }
  }
};

export default (getToken, httpClient = tkhttpClient) => {
  const convertDataProviderRequestToHTTP = (type, resource, params) => {
    let options = {};
    let url = '';
    switch (type) {
      case GET_LIST: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
          ...fetchUtils.flattenObject(params.filter),
          _sort: field,
          _order: order,
          _start: (page - 1) * perPage,
          _end: page * perPage,
        };
        url = `${API_URL_ADMIN}/${resource}?${stringify(query)}`
        break;
      }
      case GET_ONE: {
        url = `${API_URL_ADMIN}/${resource}/${params.id}?unpopulate=true`;
        break;
      }

      case GET_MANY: {
        const query = {
          filters: params.ids ? JSON.stringify({
            ids: params.ids
          }) : {}
        };
        url = `${API_URL_ADMIN}/${resource}?${stringify(query)}`;
        break;
      }
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
          ...fetchUtils.flattenObject(params.filter),
          _sort: field,
          _order: order,
          _start: (page - 1) * perPage,
          _end: page * perPage,
        };
        url = `${API_URL_ADMIN}/${resource}?${stringify(query)}`;
        break;
      }
      case UPDATE:
        url = `${API_URL_ADMIN}/${resource}/${params.id}`;
        options = {
          method: 'PUT', body: customDataRequest(resource, params),
        };
        break;
      case CREATE:
        url = `${API_URL_ADMIN}/${resource}`;
        options = {
          method: 'POST', body: customDataRequest(resource, params),
        };
        break;
      case DELETE:
        url = `${API_URL_ADMIN}/${resource}/${params.id}`;
        options = { method: 'DELETE' };
        break;
      case DELETE_MANY:
        url = `${API_URL_ADMIN}/${resource}`;
        options = { method: 'DELETE' };
        break;
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The Data Provider request params, depending on the type
   * @returns {Object} Data Provider response
   */
  const customDataResponse = (resource, json, headers, ...type) => {
    const dataJson = json;
    if (type.includes('GET_LIST')) {
      if (resource === 'honoredStudents') {
        dataJson.forEach((el) => {
          el.prize = el.prize.map(prizeItem => ({ prize: prizeItem }));
        });
      }
      return ({
        data: dataJson.map(item => ({ ...item, id: item._id })),
        total: parseInt(headers.get('x-total-count').split('/').pop(), 10),
      });
    }
    if (type.includes('GET_ONE') || type.includes('UPDATE') || type.includes('DELETE')) {
      if (resource === 'honoredStudents') {
        dataJson.prize = dataJson.prize.map(el => ({ prize: el }));
      }
      return { data: { ...dataJson, id: dataJson ? dataJson._id : null } };
    }
  };
  const convertHTTPResponseToDataProvider = (
    response,
    type,
    resource,
    params,
  ) => {
    const { headers } = response;
    const { json } = response;
    switch (type) {
      case GET_LIST:
        return customDataResponse(resource, json, headers, GET_LIST);
      case CREATE:
        return { data: { ...params.data, id: json._id } };
      case GET_ONE:
      case UPDATE:
      case DELETE:
        return customDataResponse(resource, json, headers, GET_ONE, UPDATE, DELETE);
      case GET_MANY:
        if (!headers.has('x-total-count')) {
          throw new Error(
            'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?',
          );
        }
        return {
          data: json.map(item => ({ ...item, id: item._id })),
          total: parseInt(headers.get('X-Total-Count').split('/').pop(), 10),
        };
      default:
        return { data: json };
    }
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for response
   */
  return async (type, resource, params) => {
    // json-server doesn't handle filters on DELETE route,
    // so we fallback to calling DELETE n times instead

    const { url, options } = convertDataProviderRequestToHTTP(
      type,
      resource,
      params,
    );

    if (getToken) {
      const token = await getToken();
      options.headers = new Headers({
        ...options.headers || {},
        'x-auth-token': token,
      });
    }
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map(id => httpClient(`${url}/${id}`, options)),
      ).then(responses => ({
        data: responses.map(response => (response.json ? response.json._id : null)),
      }));
    }
    return httpClient(url, options).then(response => convertHTTPResponseToDataProvider(response, type, resource, params));
  }
};

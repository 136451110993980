import React from 'react';
import {
  SaveButton,
  withDataProvider,
} from 'react-admin';

import { ROOT_API } from '../constant/url'

const handleSubmitWithPreview = (props) => () => {
	props.dataProvider(props.type, props.resource, {
		data: props.formData,
		id: props.formData._id,
	}).then(() => {
		window.open(`${ROOT_API}/blog/post/${props.formData.slug}?preview=true`, '_blank');
	})
};

export default withDataProvider((props) => {
	return <SaveButton
		label="Xem trước"
		redirect={false}
		submitOnEnter={false}
		variant="flat"
		handleSubmitWithRedirect={() => handleSubmitWithPreview(props)}
	/>;
});
import React, { Fragment } from 'react';
import {
  List,
  Datagrid,
  TextField,
  Edit,
  Create,
  ArrayInput,
  RichTextField,
  BooleanField,
  DateField,
  BooleanInput,
  TextInput,
  SelectInput,
  TabbedForm,
  FormTab,
  SimpleFormIterator,
  SelectArrayInput,
  Filter,
  FormDataConsumer,
  TabbedFormTabs,
  ReferenceInput,
  AutocompleteInput,
  required
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { Paper } from 'yoastseo';
import UploadField from '../customs/Upload';
import SerpPreview from '../customs/SerpPreview';
import ContentAnalysis from '../customs/ContentAnalysis';
import { ROOT_FRONT_END } from '../constant/url';
import { formatMoney } from '../helpers/formatMoney';
import TextEditor from '../customs/TextEditor';
import isEqual from 'lodash/isEqual';
import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash/get';

const useStyles = makeStyles({
  horzContainter: {
    display: 'flex',
    flexDirection: 'row',
    '& *': {
      marginRight: '1rem',
      flexGrow: 0,
    },
  },
  fullWidth: {
    width: '100%',
  },
  factRows: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '& > *': {
      width: 'calc(25% - 1rem)',
    },
  },
  registerRow: {
    width: '100%',
    display: 'flex',
    '& > *': {
      width: 'calc(25% - 1rem)',
      marginRight: '1rem',
    },
  },
  halfWidth: {
    width: '50%',
  },
});

const formatProduct = (record) => {
  if (!record || isEqual(record, { id: null })) {
    return 'Trống';
  } else {
    const codeString = record.code ? `${record.code}` : '';
    const nameString = record.name ? ` (${record.name})` : '';
    return `${codeString}${nameString} - ${formatMoney(record.price)}`;
  }
};

const formatCourse = (record) => {
  if (!record || isEqual(record, { id: null })) {
    return 'Trống';
  } else {
    const nameString = record.name ? `${record.name}` : '';
    const shortNameString = record.shortName ? ` [${record.shortName}] - ` : '';
    const activeString = record.isActive ? ' (Active)' : ' (Inactive)';
    return `${shortNameString}${nameString}${activeString}`;
  }
};

const levels = [
  {
    id: 'beginner',
    name: 'Cơ bản',
  },
  {
    id: 'advanced',
    name: 'Nâng cao',
  },
  {
    id: 'intensive',
    name: 'Chuyên sâu',
  },
];

const EmptyActions = () => <Fragment />;

const MethodFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
  </Filter>
);

const choices = [
  { id: 'image', name: 'Hiện ảnh' },
  { id: 'video', name: 'Hiện video' },
];

export const CoursesList = (props) => {
  return (
    <List
      {...props}
      bulkActionButtons={<EmptyActions />}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      filters={<MethodFilter />}
    >
      <Datagrid rowClick='edit'>
        <TextField source='name' />
        <TextField source='slug' />
        <TextField source='maxSession' />
        <BooleanField source='isActive' />
        <RichTextField source='description' />
        <DateField source='createdAt' />
        <DateField source='updatedAt' />
      </Datagrid>
    </List>
  );
};

const colorList = [
  '#ffffff',
  '#000000',
  '#242424',
  '#4f4f4f',
  '#79797C',
  '#3E3E3C',
  '#9C9C9C',
  '#F2F0F1',
  '#D2D3DB',
  '#F1F1F1',
  '#C00000',
  '#E8392E',
  '#ff00f5',
];

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],
  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ direction: 'rtl' }], // text direction
  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: colorList }, { background: colorList }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
  ['clean'], // remove formatting button
];

const allRegisterPaths = [
  {
    id: '/register/care',
    name: 'Tư vấn  - /register/care',
  },
  {
    id: '/register/appointment',
    name: 'Trải nghiệm  - /register/appointment',
  },
  {
    id: '/register/now',
    name: 'Đăng ký ngay  - /register/now',
  },
];

const InnerForm = (props) => {
  const classes = useStyles();
  return (
    <TabbedForm tabs={<TabbedFormTabs scrollable />} {...props}>
      <FormTab label='Thông tin cơ bản'>
        <TextInput source='name' label='Tên' validate={required()} />
        <TextInput source='slug' />
        <BooleanInput source='isActive' defaultValue={true} />
        <SelectArrayInput source='levels' label='Trình độ' choices={levels} />
        <UploadField source='img' label='Ảnh đại diện khoá học' />
        <UploadField source='header.img' label='Ảnh header khoá học' />
      </FormTab>
      <FormTab label='Giới thiệu'>
        <TextInput
          source='description'
          label='Mô tả'
          className={classes.fullWidth}
        />
        <ArrayInput source='overview.hookContents' label='Hooks'>
          <SimpleFormIterator>
            <RichTextInput source='' label='' toolbar={toolbarOptions} />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source='overview.images' label='Ảnh giới thiệu'>
          <SimpleFormIterator>
            <UploadField source='' label='Ảnh' />
          </SimpleFormIterator>
        </ArrayInput>
        <TextInput
          source='overview.shortSEO'
          multiline
          label='SEO phiên bản ngắn'
          className={classes.fullWidth}
        />
        <TextEditor source='overview.longSEO' label='SEO phiên bản dài' />
        <div className={classes.factRows}>
          <TextInput
            source='overview.facts.targetStudent'
            label='Đối tượng học viên'
          />
          <TextInput
            source='overview.facts.schedule'
            multiline
            label='Lịch học'
          />
          <TextInput
            source='overview.facts.totalSession'
            multiline
            label='Tổng số buổi'
          />
          <TextInput
            source='overview.facts.numberOfStudent'
            multiline
            label='Tổng số học viên'
          />
        </div>
      </FormTab>
      <FormTab label='Đăng ký'>
        <div className={classes.registerRow}>
          <TextInput source='register.actionText' label='Call to action' />
          <SelectInput
            source='register.path'
            label='Link đăng ký'
            choices={allRegisterPaths}
          />
        </div>
        <ReferenceInput
          source='register.baseCourse'
          reference='baseCourses'
          label='Khoá học tương ứng trên BASE/CRM/LMS'
          filterToQuery={(searchText) => ({ q: searchText })}
          className={classes.halfWidth}
          validate={required()}
        >
          <AutocompleteInput optionText={(record) => formatCourse(record)} />
        </ReferenceInput>
      </FormTab>
      <FormTab label='Lợi ích'>
        <SelectInput
          source='benefit.currentResourceDisplay'
          label='Chọn thông tin cần hiện'
          choices={choices}
        />
        <UploadField source='benefit.img' label='Ảnh' />
        <TextInput source='benefit.videoUrl' label='Link youtube video' />
        <TextInput source='benefit.title' label='Tiêu đề' />
        <ArrayInput
          source='benefit.contents'
          style={{ width: '100%' }}
          label='Nội dung'
        >
          <SimpleFormIterator>
            <TextInput
              multiline
              label='content'
              source=''
              className={classes.fullWidth}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label='Nội dung khoá'>
        <ArrayInput
          source='contents'
          style={{ width: '100%' }}
          label='Nội dung'
        >
          <SimpleFormIterator>
            <UploadField source='img' label='Ảnh' />
            <TextInput
              source='title'
              label='Tiêu đề'
              className={classes.fullWidth}
            />
            <RichTextInput
              source='description'
              label='Mô tả'
              toolbar={toolbarOptions}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label='Điểm nổi bật'>
        <ArrayInput
          source='standoutPoints'
          style={{ width: '100%' }}
          label='Điểm nổi bật'
        >
          <SimpleFormIterator>
            <UploadField source='img' label='Ảnh' />
            <TextInput
              source='title'
              label='Tiêu đề'
              className={classes.fullWidth}
            />
            <TextInput
              multiline
              source='description'
              label='Mô tả'
              className={classes.fullWidth}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label='Lý do nên học'>
        <ArrayInput source='reasons' style={{ width: '100%' }} label='Lý do'>
          <SimpleFormIterator>
            <UploadField source='img' label='Ảnh' />
            <TextInput
              source='title'
              label='Tiêu đề'
              className={classes.fullWidth}
            />
            <TextInput
              multiline
              source='content'
              label='Nội dung'
              className={classes.fullWidth}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label='SEO Tags'>
        <TextInput
          multiline
          source='seoTags.title'
          label='Title'
          className={classes.fullWidth}
        />
        <TextInput
          multiline
          source='seoTags.keywords'
          label='Keyword'
          className={classes.fullWidth}
        />
        <TextInput
          multiline
          source='seoTags.description'
          label='Description'
          className={classes.fullWidth}
        />

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            let data = {
              title:
                formData.seoTags && formData.seoTags.title
                  ? formData.seoTags.title
                  : 'Example Title',
              metaDescription:
                formData.seoTags && formData.seoTags.description
                  ? formData.seoTags.description
                  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse placerat vitae massa eu cursus. In fringilla ligula tempor augue congue ullamcorper. Morbi dolor nunc, sollicitudin a sagittis a, dictum rhoncus massa. Curabitur sit amet vestibulum erat. Pellentesque in nunc porta tellus finibus egestas. Integer sapien eros, lobortis at vulputate ac, finibus sit amet libero. Fusce eu semper enim. Pellentesque in malesuada justo, nec vestibulum ipsum. Morbi tincidunt magna eu sagittis aliquam. Integer porta massa at massa euismod gravida. Cras vitae hendrerit massa. Nullam eget tristique augue, in efficitur leo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Aenean finibus arcu vitae ullamcorper pretium. Phasellus condimentum justo tellus. Duis faucibus sagittis rutrum.',
              url:
                `https://mindx.edu.vn/course/${formData.slug}` ||
                'https://examples.com/example-slug-url',
            };
            return <SerpPreview {...data} />;
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const paper = new Paper(formData.content || '', {
              description:
                formData.seoTags && formData.seoTags.description
                  ? formData.seoTags.description
                  : '',
              keyword:
                formData.seoTags && formData.seoTags.keywords
                  ? formData.seoTags.keywords
                  : '',
              locale: 'vi_VN',
              permalink: `${ROOT_FRONT_END}/courses/${formData.slug}` || '',
              synonyms: '',
              title:
                formData.seoTags && formData.seoTags.title
                  ? formData.seoTags.title
                  : '',
              titleWidth:
                formData.seoTags && formData.seoTags.title
                  ? formData.seoTags.title.length
                  : 0,
              url: formData.slug || '',
            });
            return <ContentAnalysis paper={paper} />;
          }}
        </FormDataConsumer>
      </FormTab>
      <FormTab label='Giá'>
        <ArrayInput source='commonPrices'>
          <SimpleFormIterator>
            <TextInput source='title' label='Tên' />
            <TextInput source='description' label='Mô tả' />
            <BooleanInput source='price.useProduct' label='Gắn với sản phẩm' />
            <FormDataConsumer>
              {({ formData, getSource, ...rest }) => {
                const useProduct = _.get(
                  formData,
                  getSource('price.useProduct'),
                );
                return useProduct ? (
                  <div className={classes.horzContainter}>
                    <ReferenceInput
                      {...rest}
                      allowEmpty
                      source={getSource('price.singleProduct')}
                      reference='products'
                      label='Sản phẩm đơn'
                      filterToQuery={(searchText) => ({ q: searchText })}
                    >
                      <AutocompleteInput
                        optionText={(record) => formatProduct(record)}
                      />
                    </ReferenceInput>
                    <ReferenceInput
                      {...rest}
                      allowEmpty
                      source={getSource('price.multiProduct')}
                      reference='products'
                      label='Sản phẩm combo/lộ trình'
                      filterToQuery={(searchText) => ({ q: searchText })}
                    >
                      <AutocompleteInput
                        optionText={(record) => formatProduct(record)}
                      />
                    </ReferenceInput>
                  </div>
                ) : (
                  <>
                    <TextInput
                      {...rest}
                      source={getSource('price.perCourse')}
                      label='Giá một khoá'
                      style={{ marginRight: '1rem' }}
                    />
                    <TextInput
                      {...rest}
                      source={getSource('price.total')}
                      label='Giá cả lộ trình'
                    />
                  </>
                );
              }}
            </FormDataConsumer>
            <ArrayInput
              source='benefits'
              label='Danh sách lợi ích'
              style={{ width: '100%' }}
            >
              <SimpleFormIterator>
                <TextInput
                  label='content'
                  initialValue=''
                  className={classes.fullWidth}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  );
};

export const CoursesEdit = (props) => {
  return (
    <Edit {...props} title='Edit Course' redirect='edit'>
      <InnerForm />
    </Edit>
  );
};

export const CoursesCreate = (props) => {
  return (
    <Create {...props} title='Create Course' redirect='edit'>
      <InnerForm />
    </Create>
  );
};

import React, { useState } from 'react';
import { addField } from 'ra-core';
import ReactMapGL, { Marker } from 'react-map-gl';
import config from '../../environments';
import { Room } from '@material-ui/icons';
import Input from '@material-ui/core/Input';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  marker: {
    '& svg': {
      position: 'relative',
      transform: 'translate(-50%, -50%)',
    },
  },
  mapUrl: {
    width: '100%',
    marginBottom: '1rem',
  },
});

const getCoordinates = (value) => {
  const lat = Number(_.get(value, 'lat', '21.02230599912513'));
  const long = Number(_.get(value, 'long', '105.81576882923487'));
  return {
    lat,
    long,
  };
};

const MapEditor = (props) => {
  const value = props.input.value;

  const coordinates = getCoordinates(value);

  const [viewPort, setViewPort] = useState({
    width: 400,
    height: 400,
    latitude: coordinates.lat,
    longitude: coordinates.long,
    zoom: 14,
  });

  const handleClick = (e) => {
    const [long, lat] = e.lngLat;
    props.input.onChange({
      long: long.toString(),
      lat: lat.toString(),
    });
  };
  const handleGGMapUrlChange = (e) => {
    let url = e.target.value.split('@');
    url = url[1].split('z');
    url = url[0].split(',');
    props.input.onChange({
      lat: url[0],
      long: url[1],
    });
  };
  const classes = useStyles();
  return (
    <>
      <Input
        className={classes.mapUrl}
        placeholder='Google Map URL'
        onChange={(e) => handleGGMapUrlChange(e)}
      />
      <ReactMapGL
        {...viewPort}
        mapboxApiAccessToken={config.mapboxToken}
        mapStyle='mapbox://styles/mapbox/streets-v11'
        onViewportChange={(newViewport) => setViewPort(newViewport)}
        onClick={(e) => handleClick(e)}
      >
        <Marker
          latitude={coordinates.lat}
          longitude={coordinates.long}
          className={classes.marker}
        >
          <Room htmlColor='#c00000' />
        </Marker>
      </ReactMapGL>
    </>
  );
};

export default addField(MapEditor);

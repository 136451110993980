import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  BooleanField,
  Create,
  Edit,
  TextInput,
  SimpleForm,
  Filter,
  NumberInput,
  BooleanInput,
  required,
  regex
} from 'react-admin';


const MethodFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const ThirdPartyContentList = (props) => {
  return (
    <List {...props} filters={<MethodFilter />}>
      <Datagrid rowClick='edit'>
        <TextField source='title' label='Title' />
        <BooleanField source='isActive' label='Trạng thái' />
        <NumberField source='pageUrl' label='Page URL' />
        <NumberField source='thirdPartyContentUrl' label='3rd party URL' />
      </Datagrid>
    </List>
  );
};

export const ThirdPartyContentCreate = (props) => (
         <Create {...props}>
           <SimpleForm redirect='list'>
             <TextInput source='title' label='Title' validate={required()} />
             <TextInput source='pageUrl' label='Page URL' placeholder='/example' validate={[required(), regex(/^\/(?!.*\/\/)([a-zA-Z0-9-\/\&]+)$/, 'Path invalid')]} />
             <TextInput source='thirdPartyContentUrl' label='3rd party URL' validate={[required(), regex(/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/, '3rd party URL invalid')]} />
             <BooleanInput
               source='isActive'
               label='Đang hoạt động'
               defaultValue={true}
             />
           </SimpleForm>
         </Create>
       );

export const ThirdPartyContentEdit = (props) => (
         <Edit {...props} title='Edit Company'>
           <SimpleForm redirect='list'>
             <TextInput source='title' label='Title'  validate={required()} />
             <TextInput source='pageUrl' label='Page URL' placeholder='/example' validate={[required(), regex(/^\/(?!.*\/\/)([a-zA-Z0-9-\/\&]+)$/, 'Path invalid')]} />
             <TextInput source='thirdPartyContentUrl' label='3rd party URL' validate={[required(), regex(/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/, 'URL invalid')]} />
             <BooleanInput
               source='isActive'
               label='Đang hoạt động'
               defaultValue={true}
             />
           </SimpleForm>
         </Edit>
       );

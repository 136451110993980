import { ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';

import React from 'react';

const MultiCoursesSelect = (props) => {
  return (
    <ReferenceArrayInput
      {...props}
      reference='courses'
      filterToQuery={(search) => {
        return {
          q: search,
        };
      }}
    >
      <AutocompleteArrayInput
        variant='standard'
        optionText='name'
        optionValue='_id'
      />
    </ReferenceArrayInput>
  );
};

export default MultiCoursesSelect;

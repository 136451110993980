import React from 'react';
import get from 'lodash/get';
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  Filter,
  SimpleForm,
  NumberInput,
  AutocompleteInput,
  Create,
  Edit,
  FormDataConsumer,
  ReferenceInput,
  ArrayInput,
  SimpleFormIterator,
  required,
} from 'react-admin';
import { getUnicodeText } from '../helpers/strings';

const menuItemTypes = {
  Custom: 'Custom',
  Course: 'Course',
  CourseCategory: 'CourseCategory',
};

const allMenuItemTypes = Object.values(menuItemTypes);

const allMenuItemOptions = allMenuItemTypes.map((text) => ({
  id: text,
  name: text,
}));

const MethodFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
  </Filter>
);

export const MenuItemList = (props) => (
  <List {...props} filters={<MethodFilter />}>
    <Datagrid rowClick='edit'>
      <TextField source='title' label='Tiêu đề' />
      <TextField source='type' label='Loại link' />
      <TextField source='order' label='Thứ tự sắp xếp' />
    </Datagrid>
  </List>
);

export const MenuItemEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <FormDataConsumer>
        {(props) => <NestedMenuItem {...props} />}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);

export const MenuItemCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <FormDataConsumer>
        {(props) => <NestedMenuItem {...props} />}
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);

import { makeStyles } from '@material-ui/core/styles';

const styles = {
  itemsContainer: {
    '& .MuiFormControl-fullWidth': {
      width: '100%', // re-overwrite this class
    },
  },
  inputWrapper: {
    display: 'inline-block',
  },
  fixedWidth: {
    width: '11rem',
  },
  smallWidth: {
    width: '4rem',
  },
};

const useStyles = makeStyles(styles);

const NestedMenuItem = (props) => {
  const {
    formData,
    scopedFormData,
    getSource = (field) => field, // Default getSource for outermost layer of Nested Menu Item
    level = 0,
    ...rest
  } = props;
  const classes = useStyles();
  const type = get(formData, getSource('type'));
  return (
    <>
      <TextInput
        {...rest}
        label='Tiêu đề'
        source={getSource('title')}
        validate={required()}
        className={classes.fixedWidth}
      />
      <div className={classes.inputWrapper}>
        <AutocompleteInput
          {...rest}
          source={getSource('type')}
          label='Kiểu'
          choices={allMenuItemOptions}
          className={classes.fixedWidth}
          allowEmpty={false}
          validate={required()}
        />
      </div>
      {type === menuItemTypes.Custom && (
        <TextInput source={getSource('custom')} label='Link' />
      )}
      {type === menuItemTypes.Course && (
        <div className={classes.inputWrapper}>
          <ReferenceInput
            source={getSource('course')}
            reference='courses'
            label='Khoá học'
            filterToQuery={(searchText) => ({ q: searchText })}
            className={classes.fixedWidth}
            validate={required()}
            matchSuggestion={(filter, choice) => {
              const searchString = get(choice, 'searchString', '');
              const unicodeText = getUnicodeText(filter);
              return searchString.includes(unicodeText);
            }}
          >
            <AutocompleteInput optionText='name' />
          </ReferenceInput>
        </div>
      )}
      {type === menuItemTypes.CourseCategory && (
        <div className={classes.inputWrapper}>
          <ReferenceInput
            source={getSource('courseCategory')}
            reference='courseCategories'
            label='Cụm khoá học'
            filterToQuery={(searchText) => ({ search: searchText })}
            className={classes.fixedWidth}
            validate={required()}
          >
            <AutocompleteInput optionText='name' />
          </ReferenceInput>
        </div>
      )}
      <NumberInput
        {...rest}
        label='Thứ tự'
        source={getSource('order')}
        className={classes.smallWidth}
      />
      {level < 2 && (
        <div className={classes.itemsContainer}>
          <ArrayInput
            {...rest}
            label={`Menu con level ${level + 1}`}
            source={getSource('items')}
          >
            <SimpleFormIterator>
              <FormDataConsumer>
                {(props) => <NestedMenuItem {...props} level={level + 1} />}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </div>
      )}
    </>
  );
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { addField, FieldTitle } from 'ra-core';
import CKEditor from "react-ckeditor-component";

import { API_URL_UPLOAD, ROOT_FRONT_END } from '../../constant/url';

export class TextEditor extends Component {
  state = {
    content: "",
    token: localStorage.getItem('token'),
  }

  handleBlur = (eventOrValue) => {
    this.props.onBlur(eventOrValue);
    this.props.input.onBlur(eventOrValue);
  };

  handleFocus = (event) => {
    this.props.onFocus(event);
    this.props.input.onFocus(event);
  };

  handleChange = (evt) => {
    const newContent = evt.editor.getData();
    this.props.onChange(newContent);
    this.props.input.onChange(newContent);
  };

  render() {
    const {
      className,
      input,
      isRequired,
      label,
      meta,
      options,
      resource,
      source,
      type,
      ...rest
    } = this.props;
    const { token } = this.state;
    if (typeof meta === 'undefined') {
      throw new Error(
        "The TextInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details.",
      );
    }
    
    const { touched, error } = meta;
    return (
      <div style={{ marginTop: '16px', marginBottom: '10px' }} key={source}>
        {label === false ? (
          label
        ) : (
          <div
            style={{
              paddingBottom: '10px',
              fontSize: '0.75rem',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              color: 'rgba(0, 0, 0, 0.54)'
            }}
          >
            <FieldTitle
              label={label}
              source={source}
              resource={resource}
              isRequired={isRequired}
            />
            
          </div>
        )}
        <CKEditor
          scriptUrl={`${window.location.origin}/ckeditor/ckeditor.js`}
          content={input.value}
          config={{
            filebrowserUploadUrl: `${API_URL_UPLOAD}?formType=editor_upload`
          }}
          events={{
            "change": this.handleChange
          }}
        />
        {/* <FroalaEditor
          model={input.value}
          onModelChange={this.handleModelChange}
          tag="textarea"
          config={{
            heightMax: 500,
            imageUploadMethod: 'POST',
            imageUploadURL: API_URL_UPLOAD,
            requestHeaders: {
              'Authorization': `Bearer ${token}`,
            },
            imageMaxSize: 5 * 1024 * 1024,
            imageUploadParams: {
              type: 'froala_upload',
            },
            // events : {
            //   'froalaEditor.image.uploaded' : function (e, editor, response) {
            //     console.log(e, editor, response);
            //     try {
            //       const responseJson = JSON.parse(response);
            //       if(responseJson.link) {
            //         editor.image.insert(`${ROOT_FRONT_END}${responseJson.link}`, false, null, editor.image.get(), null);
            //       }
            //     } catch (error) {
            //       console.log("aaaaaa", error);
            //     }
            //     return false;
            //   },
            // },
          }}
        /> */}
      </div>
    );
  }
}

TextEditor.propTypes = {
  className: PropTypes.string,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  meta: PropTypes.object,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  type: PropTypes.string,
};

TextEditor.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  options: {},
  type: 'text',
};

export default addField(TextEditor);

import React, { cloneElement, useState } from 'react';

import {
  List,
  Datagrid,
  BooleanField,
  Edit,
  BooleanInput,
  DateField,
  SimpleForm,
  TextField,
  TextInput,
  Create,
  TopToolbar,
  Filter,
  CreateButton,
  useListContext,
  sanitizeListRestProps,
  FormDataConsumer,
} from 'react-admin';
import { useRedirectWithMessage } from '../hooks';
import { SlugInput } from '../customs/SlugInput';

const MethodFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
  </Filter>
);

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

export const SlugRedirectList = (props) => (
  <List {...props} actions={<ListActions />} filters={<MethodFilter />}>
    <Datagrid rowClick='edit'>
      <TextField source='fromSlug' label='Từ slug' />
      <TextField source='toSlug' label='Tới slug' />
      <BooleanField source='isActive' label='Đang hoạt động' />
      <DateField source='updatedAt' label='Ngày cập nhật' />
      <DateField source='createdAt' label='Ngày tạo' />
    </Datagrid>
  </List>
);

const renderFormInner = (isCreate) => {
  const statusFieldProps = isCreate ? { defaultValue: true } : {};
  return (
    <SimpleForm>
      <FormDataConsumer>
        {(formData) => {
          return (
            <>
              <div>
                <SlugInput
                  initValue={formData.formData}
                  source='fromSlug'
                  label='Từ slug'
                />
              </div>
              <div>
                <SlugInput
                  initValue={formData.formData}
                  source='toSlug'
                  label='Tới slug'
                />
              </div>
            </>
          );
        }}
      </FormDataConsumer>
      <BooleanInput
        source='isActive'
        label='Đang hoạt động'
        {...statusFieldProps}
      />
    </SimpleForm>
  );
};

export const SlugRedirectEdit = (props) => (
  <Edit title='Edit' {...props}>
    {renderFormInner()}
  </Edit>
);

export const SlugRedirectCreate = (props) => {
  const onSuccess = useRedirectWithMessage({
    message: 'create slug redirect successfully',
    url: '/slugRedirect',
  });

  return (
    <Create undoable={false} onSuccess={onSuccess} {...props}>
      {renderFormInner(true)}
    </Create>
  );
};

module.exports = {
  port: 7798,
  url: 'https://web-dev.mindx.edu.vn',
  api: {
    root: 'https://web-api-dev.mindx.edu.vn',
  },
  id: {
    url: 'https://id.mindx.edu.vn',
  },
  resources: {
    url: 'https://resources-dev.mindx.edu.vn',
  },
  firebase: {
    apiKey: 'AIzaSyDCr7hmxHj8p8XYS5RJDCARR-uN5pRos68',
    authDomain: 'tk-id-service-prod.firebaseapp.com',
    databaseURL: 'https://tk-id-service-prod.firebaseio.com',
    projectId: 'tk-id-service-prod',
    storageBucket: 'tk-id-service-prod.appspot.com',
    messagingSenderId: '553178521043',
    lmsAPI: 'https://lmsapi.mindx.vn',
  },
  mapboxToken:
    'pk.eyJ1IjoidGVjaGRvdG1pbmR4IiwiYSI6ImNrY2E2MWxrNDFieDAzMm54YzUxNTBxZW4ifQ.bGcXpFbK61tF-PCy4P1N5g',
  webUrl: 'https://web-dev.mindx.edu.vn',
  googleFont:{
    fontFamily: 'arial, sans-serif',
    fontSize: '22px',
  },
  facebookAppId: '222201346719420',
  oaIdZalo: '2993265874529951614',
  webUrlRegexPattern: `https:\\/\\/mindx\\.edu\\.vn`,
  webDevUrlRegexPattern: `https:\\/\\/web-dev\\.mindx\\.edu\\.vn`,
  webStagingUrlRegexPattern: `https:\\/\\/web-staging\\.mindx\\.edu\\.vn`,
  webPageConfigTypes:{
    HomePageConfigs: 'homePageConfigs',
    BlogPageConfigs: 'blogPageConfigs'
  },
  screenSize:{
    mobile: 475,
    tablet: 768,
    largeTablet: 992,
  },
  pageConfigTypes: [
    {
      id: 'homePageConfigs',
      name: 'Home page config',
    },
    {
      id: 'blogPageConfigs',
      name: 'Blog page configs'
    }
  ],
  incrementalStaticRevalidateTime: 1,
  studyProgramBenefitShortVersionLines: 2,
  documentTagId: '60b4d1754328e64a61f05e20',
  defaultBannerVideoUrl: './static/videos/banner-video.mp4',
};

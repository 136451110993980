import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { API_URL_ADMIN } from '../constant/url';
import { getToken } from '../helpers/auth';
import CustomInput from './CustomInput';
import _ from 'lodash';

const slugRegex = new RegExp('^[a-z0-9]+(?:-[a-z0-9]+)*$');

const InputWithSlug = (props) => {
  const { subApi, resourceId, initValue, source, label } = props;

  const [data, setData] = useState(initValue[source]);
  const [dataError, setDataError] = useState();
  const [slug, setSlug] = useState(initValue.slug);
  const [slugError, setSlugError] = useState();

  const preSlugRef = useRef();

  const validateTitle = (value) => {
    if (!value || value.trim() == '') {
      setDataError(`${label} is required`);
      return `${label} is required`;
    } else {
      setDataError(null);
    }
  };

  const validateSlug = async (slug) => {
    // check slug is different with previous slug or not
    if (preSlugRef.current !== slug) {
      if (!slugRegex.test(slug)) {
        setSlugError('slug is invalid');
        return 'slug is invalid';
      }
      if (!slug || slug.trim() == '') {
        setSlugError('slug is required');
        return 'slug is required';
      } else {
        const OutStandResult = await (await getExistedSlug(slug, resourceId))
          .data;
        if (OutStandResult) {
          setSlugError('slug already exists');
          return 'slug already exists';
        }
      }
      setSlugError(null);
    }
  };

  const getUniqueSlug = async (value, id) => {
    if (value && value.trim() !== '') {
      const token = await getToken();
      const data = {
        data: value,
        _id: id,
      };
      return await axios(`${API_URL_ADMIN}/${subApi}/:getUniqueSlug`, {
        method: 'POST',
        headers: {
          'x-auth-token': token,
        },
        data,
      });
    }
  };

  const getExistedSlug = async (slug, id) => {
    const token = await getToken();
    const data = {
      slug,
    };
    if (id) {
      data['_id'] = {
        $ne: id,
      };
    }
    return await axios(`${API_URL_ADMIN}/${subApi}/:fetch`, {
      method: 'POST',
      headers: {
        'x-auth-token': token,
      },
      data,
    });
  };

  const handleSlugChange = (data) => {
    setSlug(data);
  };

  const handleTitleChange = (data) => {
    setData(data);
  };

  useEffect(() => {
    // save previous slug to Ref
    preSlugRef.current = slug;
  }, [slug]);

  const debouncedFetchUniqueSlug = useRef(
    _.debounce((value) => {
      getUniqueSlug(value, resourceId).then((response) => {
        if (response) {
          setSlug(response.data);
        }
      });
    }, 1000),
  ).current;

  useEffect(() => {
    if (!resourceId) {
      // send Api to get Unique slug when user stop typing
      debouncedFetchUniqueSlug(data);
    }
  }, [data]);

  return (
    <>
      <CustomInput
        validate={validateTitle}
        currentValue={data}
        handleOnChange={handleTitleChange}
        error={dataError}
        source={source}
        label={label}
      />
      <div>
        <CustomInput
          validate={validateSlug}
          currentValue={slug}
          source='slug'
          label='Slug'
          error={slugError}
          handleOnChange={handleSlugChange}
        />
      </div>
    </>
  );
};

export default InputWithSlug;

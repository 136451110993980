import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InputLabel, FormHelperText, FormControl } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Chip from 'material-ui/Chip/Chip';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import compose from 'recompose/compose';
import classnames from 'classnames';
import SelectField from 'material-ui-superselectfield/es';
import { addField, translate, FieldTitle } from 'ra-core';
import { theme } from '../styles/theme';

const sanitizeRestProps = ({
  addLabel,
  allowEmpty,
  basePath,
  choices,
  className,
  component,
  crudGetMatching,
  crudGetOne,
  defaultValue,
  filter,
  filterToQuery,
  formClassName,
  initializeForm,
  input,
  isRequired,
  label,
  limitChoicesToValue,
  locale,
  meta,
  onChange,
  options,
  optionValue,
  optionText,
  perPage,
  record,
  reference,
  resource,
  setFilter,
  setPagination,
  setSort,
  sort,
  source,
  textAlign,
  translate,
  translateChoice,
  validation,
  ...rest
}) => rest;

const styles = (theme) => ({
  root: {
    marginBottom: '30px',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  select: {
    height: 'auto',
    overflow: 'auto',
  },
});

export class CheckBoxArrayInput extends Component {
  constructor(props) {
    super(props);

    const { choices, input, optionText, optionValue } = props;
    let value = [];
    if (choices && choices.length > 0 && input.value) {
      value = choices
        .filter((choice) => input.value.indexOf(choice._id) > -1)
        .map((choice) => ({
          label: choice[optionText],
          value: choice[optionValue],
        }));
    }

    this.state = {
      value,
    };
  }

  handleChange = (values) => {
    this.props.input.onChange(values.map((item) => item.value));
    // HACK: For some reason, redux-form does not consider this input touched without calling onBlur manually
    this.props.input.onBlur(values.map((item) => item.value));
    this.setState({ value: values });
  };

  handleInputType = (value) => {
    this.props.onInputValueChange && this.props.onInputValueChange(value);
  };

  onRequestDelete = (deletedValue) => (event) => {
    const { value } = this.state;
    const newValues = value.filter((v) => v.value !== deletedValue);
    this.props.input.onChange(newValues.map((item) => item.value));
    // HACK: For some reason, redux-form does not consider this input touched without calling onBlur manually
    this.props.input.onBlur(newValues.map((item) => item.value));
    this.setState({ value: newValues });
  };

  handleDisplaySelections = (name) => (values) =>
    values.length ? (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {values.map(({ label, value }, index) => {
          return (
            <Chip
              key={value}
              style={{ margin: 5 }}
              onRequestDelete={this.onRequestDelete(value)}
            >
              {label}
            </Chip>
          );
        })}
      </div>
    ) : (
      ''
    );

  renderChoices = (choices) => {
    const { optionText, optionValue } = this.props;
    return choices.map((choice) => (
      <div
        key={choice[optionValue]}
        value={choice[optionValue]}
        label={choice[optionText]}
      >
        <div style={{ marginRight: 10 }}>
          <span>{choice[optionText]}</span>
        </div>
      </div>
    ));
  };

  render() {
    const {
      choices,
      classes,
      className,
      isRequired,
      label,
      meta,
      options,
      resource,
      source,
      optionText,
      optionValue,
      input,
      ...rest
    } = this.props;
    if (typeof meta === 'undefined') {
      throw new Error(
        "The SelectInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details.",
      );
    }
    const { touched, error, helperText = false } = meta;
    return (
      <FormControl
        margin='normal'
        className={classnames(classes.root, className)}
        error={!!(touched && error)}
        {...sanitizeRestProps(rest)}
      >
        {this.state.value ? (
          <MuiThemeProvider>
            <SelectField
              floatingLabel={
                <InputLabel htmlFor={source} style={{ width: 'max-content' }}>
                  <FieldTitle
                    label={label}
                    source={source}
                    resource={resource}
                    isRequired={isRequired}
                  />
                </InputLabel>
              }
              name={source}
              multiple
              keepSearchOnSelect
              withResetSelectAllButtons
              checkPosition='left'
              hintTextAutocomplete='Type to search...'
              value={this.state.value || []}
              onChange={this.handleChange}
              onAutoCompleteTyping={this.handleInputType}
              selectionsRenderer={this.handleDisplaySelections(source)}
              style={{ position: 'relative', marginTop: '16px' }}
              floatingLabelStyle={{
                top: this.state.value.length > 0 ? '-36px' : '-20px',
                position: 'absolute',
              }}
              floatingLabelFocusStyle={{
                top: '-36px',
              }}
              showAutocompleteThreshold='always'
              underlineFocusStyle={{
                borderColor: theme.palette.primary.dark,
              }}
              autocompleteUnderlineFocusStyle={{
                borderColor: theme.palette.primary.dark,
              }}
            >
              {this.renderChoices(choices)}
            </SelectField>
          </MuiThemeProvider>
        ) : (
          ''
        )}
        {touched && error && <FormHelperText error>{error}</FormHelperText>}
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
}

CheckBoxArrayInput.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.object,
  optionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]).isRequired,
  optionValue: PropTypes.string.isRequired,
  resource: PropTypes.string,
  source: PropTypes.string,
  translate: PropTypes.func.isRequired,
  translateChoice: PropTypes.bool,
};

CheckBoxArrayInput.defaultProps = {
  classes: {},
  choices: [],
  options: {},
  optionText: 'name',
  optionValue: 'id',
  translateChoice: true,
};

const EnhancedCheckBoxArrayInput = compose(
  addField,
  translate,
  withStyles(styles, { withTheme: true }),
)(CheckBoxArrayInput);

export default EnhancedCheckBoxArrayInput;

import React from 'react';
import List from '@material-ui/core/List';
import MenuItem from './MenuItem';
import { useMediaQuery } from '@material-ui/core';

export const createMenu = (items) => {
  const Menu = (props) => {
    const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
    const { onMenuClick, logout } = props;
    return (
      <div>
        <List>
          {items.map((item, index) => (
            <MenuItem
              onMenuClick={onMenuClick}
              item={item}
              key={index.toString()}
            />
          ))}
        </List>
        {isXSmall && logout}
      </div>
    );
  };
  return Menu;
};

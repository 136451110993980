import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  CssBaseline,
  FormControl,
  Input,
  InputLabel,
  Paper,
  Typography,
  withStyles,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/LockOutlined';
import './styles.css';
import firebase from 'firebase';
import loadingIcon from './loading.svg';
import config from '../../environments';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 25,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

const loadingContainerStyle = {
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  justifyItems: 'center',
};

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      errorMsg: '',
      errorCode: null,
      loading: false,
    };

    this.toggleLoading = this.toggleLoading.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  componentDidMount () {
    if (firebase.apps.length === 0) {
      firebase.initializeApp(config.firebase);
    }

    this.setState({
      loading: true,
    })

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.props.setLoggedIn(true);
      }
      this.setState({
        loading: false,
      })
    });
  }

  toggleLoading() {
    const { loading } = this.state;
    this.setState({ loading: !loading });
  }

  handleInputChange(event) {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }

  async handleLogin(event) {
    event.preventDefault();
    const { email, password } = this.state;
    this.toggleLoading();
    await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
  }

  render() {
    const { classes } = this.props;
    const { email, password, errorMsg, errorCode, loading } = this.state;

    if (loading) {
      return (
        <div style={loadingContainerStyle}>
          <img
            style={{
              width: '20%',
            }}
            src={loadingIcon}
          />
        </div>
      );
    }

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            TK-ID
          </Typography>
          <form className={classes.form} onSubmit={this.handleLogin}>
            <FormControl
              error={errorCode === 'auth/user-not-found'}
              margin="normal"
              required
              fullWidth
            >
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input
                onChange={this.handleInputChange}
                id="email"
                name="email"
                autoFocus
                value={email}
              />
            </FormControl>
            <FormControl
              error={errorCode === 'auth/wrong-password'}
              margin="normal"
              required
              fullWidth
            >
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                onChange={this.handleInputChange}
                name="password"
                type="password"
                id="password"
                value={password}
              />
            </FormControl>
            <Typography component="div">
              {errorMsg && <p style={{ color: 'red' }}>{errorMsg}</p>}
            </Typography>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign in
            </Button>
          </form>
        </Paper>
      </main>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  site: PropTypes.string.isRequired,
  setLoggedIn: PropTypes.func,
};

export default withStyles(styles)(Login);

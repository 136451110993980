import React, { Fragment } from 'react';
import {
  List,
  Show,
  Datagrid,
  TextField,
  SimpleForm,
  SimpleShowLayout,
  ChipField,
  ReferenceField,
  Filter,
  TextInput,
} from 'react-admin';

const EmptyActions = () => (
  <Fragment />
);

const MethodFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const ClassesList = (props) => (
  <List {...props}
    filters={<MethodFilter />}
    bulkActionButtons={<EmptyActions />}
    sort={{ field: 'updatedAt', order: 'DESC' }}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" label="Tên lớp" />
      <TextField source="course.name" label="Tên khóa học" />
      <ReferenceField source="centre" label="Cơ sở" reference="locations">
        <ChipField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export const ClassesEdit = (props) => (
  <Show {...props} title="Edit Classroom">
    <SimpleShowLayout>
      <TextField disabled source="name" label="Tên lớp" />
      <TextField source="course.name" label="Tên khóa học" />
      <ReferenceField source="centre" label="Cơ sở" reference="locations">
        <ChipField source="name" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

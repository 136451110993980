import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  Filter,
} from 'react-admin';
import MapEditor from '../customs/MapPicker';

const MethodFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
  </Filter>
);

export const LocationList = (props) => (
  <List {...props} filters={<MethodFilter />}>
    <Datagrid rowClick='edit'>
      <TextField source='name' label='Tên cơ sở' />
      <TextField source='address' label='Địa chỉ' />
      <TextField source='note' label='Ghi chú' />
      <TextField source='hotline' label='hotline' />
    </Datagrid>
  </List>
);

export const LocationEdit = (props) => (
  <Edit title='Edit' {...props}>
    <SimpleForm>
      <TextInput source='name' label='Tên cơ sở' disabled />
      <TextInput multiline source='address' label='Địa chỉ' disabled/>
      <TextInput source='note' label='Ghi chú' />
      <TextInput source='hotline' label='hotline' disabled/>
      <MapEditor source='coordinates' />
    </SimpleForm>
  </Edit>
);

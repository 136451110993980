import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  BooleanField,
  BooleanInput,
  Filter,
} from 'react-admin';

import TextEditor from '../customs/TextEditor';

const MethodFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const CategoryList = (props) => (
  <List {...props} filters={<MethodFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="title" />
      <TextField source="slug" />
      <TextField source="shortTitle" label="Short title" />
      <BooleanField source="config.isTrendingCategory" label="Trending Category" />
      <BooleanField source="config.isDocumentCategory" label="Document Category" />
      <DateField source="createdAt" locales="vi-VN" />
    </Datagrid>
  </List>
);

export const CategoryCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="shortTitle" label="Short title" />
      <BooleanInput source="config.isTrendingCategory" label="Trending Category" />
      <BooleanInput source="config.isDocumentCategory" label="Document Category" />
      <TextEditor source="description" />
    </SimpleForm>
  </Create>
);

const CategoryTitle = ({ record }) => {
  return <span>Category {record ? `"${record.title}"` : ''}</span>;
};

export const CategoryEdit = (props) => (
  <Edit title={<CategoryTitle />} {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="slug" />
      <TextInput source="shortTitle" label="Short title" />
      <BooleanInput source="config.isTrendingCategory" label="Trending Category" />
      <BooleanInput source="config.isDocumentCategory" label="Document Category" />
      <TextEditor source="description" />
    </SimpleForm>
  </Edit>
);
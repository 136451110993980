import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  Filter,
} from 'react-admin';

const MethodFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
  </Filter>
);

export const GeneralHotlineList = (props) => (
  <List {...props} filters={<MethodFilter />}>
    <Datagrid rowClick='edit'>
      <TextField source='name' label='Tiêu đề' />
      <TextField source='hotline' label='Hotline' />
    </Datagrid>
  </List>
);

export const GeneralHotlineCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source='name' label='Tiêu đề' />
      <TextInput source='hotline' label='Hotline' />
    </SimpleForm>
  </Create>
);

export const GeneralHotlineEdit = (props) => (
  <Edit title='Edit' {...props}>
    <SimpleForm>
      <TextInput source='name' label='Tiêu đề' />
      <TextInput source='hotline' label='Hotline' />
    </SimpleForm>
  </Edit>
);

import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  Filter,
  ReferenceArrayField,
  ReferenceArrayInput,
  SingleFieldList,
  ChipField,
  SelectArrayInput,
} from 'react-admin';

const MethodFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
  </Filter>
);

export const RegionList = (props) => (
  <List {...props} filters={<MethodFilter />}>
    <Datagrid rowClick='edit'>
      <TextField source='name' label='Tên vùng kinh doanh' />
      <ReferenceArrayField
        label='Các cơ sở'
        reference='locations'
        source='locationRefs'
      >
        <SingleFieldList>
          <ChipField source='name' />
        </SingleFieldList>
      </ReferenceArrayField>
    </Datagrid>
  </List>
);

export const RegionCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source='name' label='Tên vùng kinh doanh' />
      <TextInput source='mapUrl' label='Link google map' />
      <ReferenceArrayInput
        source='locationRefs'
        label='Cơ sở'
        reference='locations'
        perPage={100}
        filter={{ all: true }}
      >
        <SelectArrayInput>
          <ChipField source='name' />
        </SelectArrayInput>
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);

export const RegionEdit = (props) => (
  <Edit title='Edit' {...props}>
    <SimpleForm>
      <TextInput source='name' label='Tên vùng kinh doanh' />
      <TextInput source='mapUrl' label='Link google map' />
      <ReferenceArrayInput
        source='locationRefs'
        label='Cơ sở'
        reference='locations'
        perPage={100}
        filter={{ all: true }}
      >
        <SelectArrayInput>
          <ChipField source='name' />
        </SelectArrayInput>
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import debounce from 'lodash/debounce';
import config from '../../../../environments';

const style = { width: '100%' };

const SellingPointsContentInput = ({ onChange, value }) => {
  const [data, setData] = useState(value || '');
  const handleOnItemChange = (value) => {
    setData(value);
    onChange(value);
  };
  const debounceFunc = debounce(handleOnItemChange, config.inputDeBounce);
  return (
    <TextField
      style={style}
      defaultValue={data}
      onChange={(e) => {
        debounceFunc(e.target.value);
      }}
      label='Content'
    />
  );
};

export default SellingPointsContentInput;

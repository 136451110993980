import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { ChipField } from 'react-admin';
import get from 'lodash/get';

const useStyles = makeStyles({
  Success: { color: '#0d782a' },
  Failed: { color: '#969693' },
  Waiting: { color: '#969200' },
  NotTracked: { color: '#000000' },
});

export const ColoreChipField = (props) => {
  const classes = useStyles();
  return (
    <ChipField className={classes[get(props, 'record.status')]} {...props} />
  );
};

export default ColoreChipField;

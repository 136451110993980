import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  Filter,
} from 'react-admin';

const MethodFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
  </Filter>
);

export const ProductsList = (props) => (
  <List {...props} filters={<MethodFilter />}>
    <Datagrid rowClick='edit'>
      <TextField source='name' label='Tên sản phẩm' />
      <TextField source='code' label='Mã sản phẩm' />
      <TextField source='price' label='Giá sản phẩm' />
    </Datagrid>
  </List>
);

import React from 'react';
import {
  SaveButton,
  withDataProvider,
} from 'react-admin';

import { ROOT_API } from '../constant/url'

const handleSubmitWithPreview = (props) => () => {
	props.formData.status = 'publish';
	props.dataProvider(props.type, props.resource, {
		data: props.formData,
		id: props.formData._id,
	});
};

export default withDataProvider((props) => {
	return <SaveButton
		label="Xuất bản"
		redirect={false}
		submitOnEnter={false}
		variant="flat"
		handleSubmitWithRedirect={() => handleSubmitWithPreview(props)}
	/>;
});
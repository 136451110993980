import React, { useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { useLocation } from 'react-router-dom';
import { MenuItemLink } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

const shouldOpen = (items, pathName) => {
  if (!items) return false;
  return items.some((child) => {
    if (pathName && pathName.includes(child.link)) {
      return true;
    }
    return shouldOpen(child.items, pathName);
  });
};

const MenuItem = (props) => {
  const { item, onMenuClick, sidebarIsOpen } = props;
  const { items, link = '/', text, icon } = item;
  const location = useLocation();
  const [open, setOpen] = useState(shouldOpen(items, location.pathname));
  const classes = useStyles();
  if (!!items) {
    const handleClick = () => setOpen(!open);
    return (
      <>
        <ListItem button onClick={handleClick}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={text} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List component='div' className={classes.nested}>
            {items.map((child, index) => (
              <MenuItem
                item={child}
                key={index.toString()}
                sidebarIsOpen={open}
                onMenuClick={onMenuClick}
              />
            ))}
          </List>
        </Collapse>
      </>
    );
  }
  return (
    <MenuItemLink
      to={link}
      primaryText={<ListItemText primary={text} />}
      leftIcon={<ListItemIcon>{icon}</ListItemIcon>}
      onClick={onMenuClick}
      sidebarIsOpen={sidebarIsOpen}
    />
  );
};

export default MenuItem;

import CustomInput from '../CustomInput';
import React, { useState } from 'react';
import { getRelativePath } from '../../helpers/strings';
import './styles.css';

export const SlugInput = (props) => {
  const { source, label, initValue } = props;
  const [value, setValue] = useState(initValue[source]);

  const handleSlugChange = (newValue) => {
    setValue(getRelativePath(newValue));
  };

  return (
    <div className='slug-input'>
      <CustomInput
        currentValue={value}
        handleOnChange={handleSlugChange}
        source={source}
        label={label}
      />
    </div>
  );
};

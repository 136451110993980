import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Create,
  Edit,
  TextInput,
  SelectInput,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  SelectField,
  FormDataConsumer,
  Filter,
  SimpleForm,
  UPDATE,
} from 'react-admin';
import { Paper } from 'yoastseo';

import { WEB_URL, GOOGLE_FONT } from '../constant/url';

import TextEditor from '../customs/TextEditor';
import UploadField from '../customs/Upload';
import CheckBoxArrayInput from '../customs/CheckBoxArrayInput';
import SerpPreview from '../customs/SerpPreview';
import ContentAnalysis from '../customs/ContentAnalysis';
import PostPreview from '../customs/PostPreview';
import PostPublish from '../customs/PostPublish';
import { Pagination } from 'react-admin';
import { getWidthText } from '../helpers/strings';
import InputWithSlug from '../customs/InputWithSlug';
import configs from '../environments';
import get from 'lodash/get';

import './customizeStyle.css';

const statusChoices = [
  { id: 'draft', name: 'Bản nháp' },
  { id: 'publish', name: 'Đã xuất bản' },
];

const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
    {/* <TextInput label="Title" source="title" defaultValue="" /> */}
  </Filter>
);

const PostPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 100, 200, 500]} {...props} />
);

const FullNameField = ({ record }) => (
  <span>
    {record.name ? record.name.lastName : 'Have no field name'}{' '}
    {record.name ? record.name.firstName : 'Have no filed name'}
  </span>
);

export const PostList = (props) => (
  <List
    {...props}
    pagination={<PostPagination />}
    filters={<PostFilter />}
    sort={{ field: 'createdAt', order: '-1' }}
  >
    <Datagrid rowClick='edit'>
      <TextField source='title' />
      <SelectField source='status' label='Trạng thái' choices={statusChoices} />
      <ReferenceField
        label='Author'
        source='author'
        reference='users'
        linkType={false}
      >
        <FullNameField />
      </ReferenceField>
      <ReferenceArrayField
        label='Categories'
        reference='blogCategories'
        source='categories'
      >
        <SingleFieldList>
          <ChipField source='title' />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField source='views' />
      <DateField source='updatedAt' locales='vi-VN' showTime />
      <DateField source='createdAt' locales='vi-VN' showTime />
    </Datagrid>
  </List>
);

const FullName = (props) =>
  `${props.name ? props.name.lastName : ''} ${
    props.name ? props.name.firstName : ''
  }`;

const validateUserCreation = (values) => {
  const inValidDocumentLink =
    !values.resourceUrl || get(values, 'resourceUrl', '').trim() === '';
  const errors = {};
  if (
    values.tags &&
    values.tags.includes(configs.documentTagId) &&
    inValidDocumentLink
  ) {
    errors.resourceUrl = 'Document link is required if tag is Documents';
  }
  return errors;
};
export const PostCreate = (props) => (
  <Create {...props}>
    <SimpleForm validate={validateUserCreation}>
      <FormDataConsumer>
        {(formData) => {
          return (
            <InputWithSlug
              initValue={formData.formData}
              source='title'
              resourceId={props.id}
              name='title'
              subApi='blogPosts'
              label='Title'
            />
          );
        }}
      </FormDataConsumer>
      <ReferenceArrayInput
        label='Chuyên mục'
        source='categories'
        reference='blogCategories'
        filter={{ all: true }}
      >
        <CheckBoxArrayInput optionText='title' optionValue='_id' />
      </ReferenceArrayInput>
      <UploadField source='featuredImage' label='Ảnh bìa' />
      {/* <TextEditor source="shortDescription" label="Mô tả ngắn" /> */}
      <ReferenceArrayInput label='Tags' source='tags' reference='tags'>
        <CheckBoxArrayInput optionText='name' optionValue='_id' />
      </ReferenceArrayInput>
      <TextEditor source='content' label='Nội dung' />
      <TextInput source='resourceUrl' label='Đường dẫn tải tài liệu' />
      <TextInput source='seo.title' label='Tiêu đề (SEO)' />
      <TextInput source='seo.keywords' label='Keywords (SEO)' />
      <TextInput multiline source='seo.description' label='Mô tả (SEO)' />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          let data = {
            title:
              formData.seo && formData.seo.title
                ? formData.seo.title
                : 'Example Title',
            metaDescription:
              formData.seo && formData.seo.description
                ? formData.seo.description
                : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse placerat vitae massa eu cursus. In fringilla ligula tempor augue congue ullamcorper. Morbi dolor nunc, sollicitudin a sagittis a, dictum rhoncus massa. Curabitur sit amet vestibulum erat. Pellentesque in nunc porta tellus finibus egestas. Integer sapien eros, lobortis at vulputate ac, finibus sit amet libero. Fusce eu semper enim. Pellentesque in malesuada justo, nec vestibulum ipsum. Morbi tincidunt magna eu sagittis aliquam. Integer porta massa at massa euismod gravida. Cras vitae hendrerit massa. Nullam eget tristique augue, in efficitur leo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Aenean finibus arcu vitae ullamcorper pretium. Phasellus condimentum justo tellus. Duis faucibus sagittis rutrum.',
            url:
              `https://mindx.edu.vn/blog/${formData.slug}` ||
              'https://examples.com/example-slug-url',
          };
          return <SerpPreview {...data} />;
        }}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          const width = getWidthText(
            _.get(formData, 'seo.title', ''),
            _.get(GOOGLE_FONT, 'fontFamily', 'arial, sans-serif'),
            _.get(GOOGLE_FONT, 'fontSize', '22px'),
          );
          const paper = new Paper(formData.content || '', {
            description:
              formData.seo && formData.seo.description
                ? formData.seo.description
                : '',
            keyword:
              formData.seo && formData.seo.keywords
                ? formData.seo.keywords
                : '',
            locale: 'vi_VN',
            permalink: `${WEB_URL}/blog/${formData.slug}` || '',
            synonyms: '',
            title: formData.seo && formData.seo.title ? formData.seo.title : '',
            titleWidth: formData.seo && formData.seo.title ? width : 0,
            url: formData.slug || '',
          });
          return <ContentAnalysis paper={paper} />;
        }}
      </FormDataConsumer>
      <SelectInput
        source='status'
        label='Trạng thái'
        defaultValue='draft'
        choices={statusChoices}
      />
    </SimpleForm>
  </Create>
);

const PostTitle = ({ record }) => {
  return <span>Post {record ? `"${record.title}"` : ''}</span>;
};

export const PostEdit = (props) => (
  <Edit title={<PostTitle />} {...props}>
    <SimpleForm className='PostEditCss' validate={validateUserCreation}>
      <FormDataConsumer>
        {(formData) => {
          return (
            <InputWithSlug
              initValue={formData.formData}
              source='title'
              resourceId={props.id}
              name='title'
              subApi='blogPosts'
              label='Title'
            />
          );
        }}
      </FormDataConsumer>
      <TextInput disabled source='views' label='Lượt xem' />
      <ReferenceArrayInput
        label='Chuyên mục'
        source='categories'
        reference='blogCategories'
        filter={{ all: true }}
      >
        <CheckBoxArrayInput optionText='title' optionValue='_id' />
      </ReferenceArrayInput>
      <UploadField source='featuredImage' label='Ảnh bìa' />
      {/* <TextEditor source="shortDescription" label="Mô tả ngắn" /> */}
      <ReferenceArrayInput label='Tags' source='tags' reference='tags'>
        <CheckBoxArrayInput optionText='name' optionValue='_id' />
      </ReferenceArrayInput>
      <TextEditor source='content' label='Nội dung' />
      <TextInput source='resourceUrl' label='Đường dẫn tải tài liệu' />
      <TextInput source='seo.title' label='Tiêu đề (SEO)' />
      <TextInput source='seo.keywords' label='Keywords (SEO)' />
      <TextInput multiline source='seo.description' label='Mô tả (SEO)' />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          let data = {
            title:
              formData.seo && formData.seo.title
                ? formData.seo.title
                : 'Example Title',
            metaDescription:
              formData.seo && formData.seo.description
                ? formData.seo.description
                : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse placerat vitae massa eu cursus. In fringilla ligula tempor augue congue ullamcorper. Morbi dolor nunc, sollicitudin a sagittis a, dictum rhoncus massa. Curabitur sit amet vestibulum erat. Pellentesque in nunc porta tellus finibus egestas. Integer sapien eros, lobortis at vulputate ac, finibus sit amet libero. Fusce eu semper enim. Pellentesque in malesuada justo, nec vestibulum ipsum. Morbi tincidunt magna eu sagittis aliquam. Integer porta massa at massa euismod gravida. Cras vitae hendrerit massa. Nullam eget tristique augue, in efficitur leo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Aenean finibus arcu vitae ullamcorper pretium. Phasellus condimentum justo tellus. Duis faucibus sagittis rutrum.',
            url:
              `https://mindx.edu.vn/blog/${formData.slug}` ||
              'https://examples.com/example-slug-url',
          };
          return <SerpPreview {...data} />;
        }}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          const width = getWidthText(
            _.get(formData, 'seo.title', ''),
            _.get(GOOGLE_FONT, 'fontFamily', 'arial, sans-serif'),
            _.get(GOOGLE_FONT, 'fontSize', '22px'),
          );
          const paper = new Paper(formData.content || '', {
            description:
              formData.seo && formData.seo.description
                ? formData.seo.description
                : '',
            keyword:
              formData.seo && formData.seo.keywords
                ? formData.seo.keywords
                : '',
            locale: 'vi_VN',
            permalink: `${WEB_URL}/blog/${formData.slug}` || '',
            synonyms: '',
            title: formData.seo && formData.seo.title ? formData.seo.title : '',
            titleWidth: formData.seo && formData.seo.title ? width : 0,
            url: formData.slug || '',
          });
          return <ContentAnalysis paper={paper} />;
        }}
      </FormDataConsumer>
      <SelectInput
        source='status'
        label='Trạng thái'
        defaultValue='draft'
        choices={statusChoices}
      />
      <FormDataConsumer>
        {({ formData, resource, basePath, ...rest }) => {
          return (
            <div>
              <PostPreview {...{ formData, resource, type: UPDATE }} />
              <PostPublish
                {...{ formData, resource, type: UPDATE, basePath }}
              />
            </div>
          );
        }}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);

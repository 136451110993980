import { Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { addField } from 'ra-core';
import React, { useEffect, useState } from 'react';
import SingleSelectBlogPostWithSearch from '../SingleSelectBlogPostWithSearch';
import SortableList from '../SortableList';
import SortableRow from '../SortableRow';

const BlogPageConfig = (props) => {
  const { initValue } = props;

  const [data, setData] = useState(initValue || {});

  useEffect(() => {
    props.input.onChange(data);
  }, [data]);

  const handleHighlightedBlogPostChange = (values) => {
    setData({
      ...(data || []),
      highlightedBlogPosts: values,
    });
  };

  const handleHighlightedDocumentChange = (values) => {
    setData({
      ...(data || []),
      highlightedDocuments: values,
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography> Các bài viết nổi bật: </Typography>
      </Grid>
      <Grid item xs={12}>
        <SortableList
          initValues={data || {}}
          onChange={(value) => {
            handleHighlightedBlogPostChange(value);
          }}
          SingleSelectComponent={SingleSelectBlogPostWithSearch}
          ListItemComponent={SortableRow}
          resource='highlightedBlogPosts'
          source='highlightedBlogPosts'
        />
      </Grid>
      <Grid item xs={12}>
        <Typography> Các tài liệu nổi bật: </Typography>
      </Grid>
      <Grid item xs={12}>
        <SortableList
          initValues={data || {}}
          onChange={(value) => {
            handleHighlightedDocumentChange(value);
          }}
          SingleSelectComponent={SingleSelectBlogPostWithSearch}
          ListItemComponent={SortableRow}
          resource='highlightedDocuments'
          source='highlightedDocuments'
        />
      </Grid>
    </Grid>
  );
};

export default addField(BlogPageConfig);

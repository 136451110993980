import {
  AUTH_LOGIN,
  AUTH_CHECK,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_GET_PERMISSIONS,
  fetchUtils,
} from 'react-admin';

import { API_URL } from '../constant/url';

const logout = async (options) => {
  try {
    const response = await fetchUtils.fetchJson(`${API_URL}/auth`, {
      method: 'DELETE',
      credentials: 'include',
      ...options,
    });
    return response.json && response.json.success;
  } catch (err) {
    return true;
  }
};

const getUserInfo = async (options) =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await fetchUtils.fetchJson(`${API_URL}/auth`, {
        method: 'GET',
        credentials: 'include',
        ...options,
      });
      resolve(response.json);
    } catch (err) {
      reject(null);
    }
  });

export default async (type, params, getToken, setLoggedIn) => {
  let options = {};
  if (getToken) {
    const token = await getToken();
    options.headers = new Headers({
      ...(options.headers || {}),
      Accept: 'application/json',
      'x-auth-token': token,
    });
  }
  if (type === AUTH_GET_PERMISSIONS) {
    const userInfo = await getUserInfo(options);
    return userInfo && userInfo.tkvnRole
      ? Promise.resolve(userInfo.tkvnRole)
      : Promise.reject();
  }
  if (type === AUTH_LOGIN) {
    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    return Promise.resolve();
  }
  if (type === AUTH_LOGOUT) {
    await logout(options);
  }
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      setLoggedIn(false);
    }
    return Promise.resolve();
  }
  return Promise.reject(new Error('Unknow method'));
};

import React, { Fragment } from 'react';
import {
  List,
  Datagrid,
  TextField,
  Edit,
  RichTextField,
  ReferenceField,
  ReferenceArrayField,
  ReferenceInput,
  SingleFieldList,
  AutocompleteInput,
  ChipField,
  BooleanField,
  BooleanInput,
  TextInput,
  Filter,
  FormDataConsumer,
  TabbedForm,
  TabbedFormTabs,
  FormTab,
} from 'react-admin';
import UploadField from '../customs/Upload';
import ColorInput from '../customs/ColorInput';
import { Paper } from 'yoastseo';
import SerpPreview from '../customs/SerpPreview';
import ContentAnalysis from '../customs/ContentAnalysis';
import TextEditor from '../customs/TextEditor';
import { ROOT_FRONT_END } from '../constant/url';
const EmptyActions = () => <Fragment />;

const MethodFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
  </Filter>
);

export const CourseCategoriesList = (props) => (
  <List
    {...props}
    filters={<MethodFilter />}
    sort={{ field: 'updatedAt', order: 'DESC' }}
    bulkActionButtons={<EmptyActions />}
  >
    <Datagrid rowClick='edit'>
      <TextField source='name' label='Tên' />
      <TextField source='slug' />
      <BooleanField source='enabled' />
      <BooleanField source='forAdult' label='For Adult' />
      <BooleanField source='forTeen' label='For Teen' />
      <ReferenceArrayField
        label='Khoá học'
        reference='courses'
        source='courseRefs'
      >
        <SingleFieldList>
          <ChipField source='name' />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceField
        label='Chương trình học'
        source='studyProgram'
        reference='studyPrograms'
      >
        <ChipField source='title' />
      </ReferenceField>
      <RichTextField source='description' />
    </Datagrid>
  </List>
);

export const CourseCategoriesEdit = (props) => (
  <Edit {...props} title='Edit Course Category'>
    <TabbedForm tabs={<TabbedFormTabs scrollable />}>
      <FormTab label='Thông tin'>
        <TextInput disabled source='name' label='Tên' />
        <TextInput source='title' label='Tiêu đề' />
        <ReferenceInput
          source='hotline'
          reference='generalHotlines'
          label='Hot line'
          filterToQuery={(searchText) => ({ name: searchText })}
        >
          <AutocompleteInput optionText='name' />
        </ReferenceInput>
        <ReferenceArrayField
          label='Khoá học'
          reference='courses'
          source='courseRefs'
        >
          <SingleFieldList>
            <ChipField source='name' />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextEditor source='benefit' label='Lợi ich từ lộ trình' />
        <BooleanInput source='enabled' label='Active' />
        <BooleanInput source='forAdult' label='Dành cho người lớn (18+)' />
        <BooleanInput source='forTeen' label='Dành cho học sinh cấp 3 (Teen)' />
        <TextInput source='slug' />
        <UploadField source='header.img' label='Ảnh header' />
        <UploadField source='icon' />
        <ColorInput source='color' label='Màu hiển thị' />
        <TextInput multiline source='description' label='Mô tả' />
        <TextInput
          multiline
          source='detailDescription'
          label='Mô tả chi tiết'
        />
        <TextInput
          source='level.beginner.title'
          label='Tiêu đề cho trình độ beginner'
        />
        <TextInput
          multiline
          source='level.beginner.description'
          label='Mô tả cho trình độ beginner'
        />
        <TextInput
          source='level.advanced.title'
          label='Tiêu đề cho trình độ advanced'
        />
        <TextInput
          multiline
          source='level.advanced.description'
          label='Mô tả cho trình độ advanced'
        />
        <TextInput
          source='level.intensive.title'
          label='Tiêu đề cho trình độ intensive'
        />
        <TextInput
          multiline
          source='level.intensive.description'
          label='Mô tả cho trình độ intensive'
        />

        <TextInput multiline source='seoTags.title' label='Title (SEO)' />
        <TextInput multiline source='seoTags.keywords' label='Keyword (SEO)' />
        <TextInput
          multiline
          source='seoTags.description'
          label='Description (SEO)'
        />

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            let data = {
              title:
                formData.seoTags && formData.seoTags.title
                  ? formData.seoTags.title
                  : 'Example Title',
              metaDescription:
                formData.seoTags && formData.seoTags.description
                  ? formData.seoTags.description
                  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse placerat vitae massa eu cursus. In fringilla ligula tempor augue congue ullamcorper. Morbi dolor nunc, sollicitudin a sagittis a, dictum rhoncus massa. Curabitur sit amet vestibulum erat. Pellentesque in nunc porta tellus finibus egestas. Integer sapien eros, lobortis at vulputate ac, finibus sit amet libero. Fusce eu semper enim. Pellentesque in malesuada justo, nec vestibulum ipsum. Morbi tincidunt magna eu sagittis aliquam. Integer porta massa at massa euismod gravida. Cras vitae hendrerit massa. Nullam eget tristique augue, in efficitur leo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Aenean finibus arcu vitae ullamcorper pretium. Phasellus condimentum justo tellus. Duis faucibus sagittis rutrum.',
              url:
                `https://mindx.edu.vn/study-program/${formData.slug}` ||
                'https://examples.com/example-slug-url',
            };
            return <SerpPreview {...data} />;
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const paper = new Paper(formData.content || '', {
              description:
                formData.seoTags && formData.seoTags.description
                  ? formData.seoTags.description
                  : '',
              keyword:
                formData.seoTags && formData.seoTags.keywords
                  ? formData.seoTags.keywords
                  : '',
              locale: 'vi_VN',
              permalink:
                `${ROOT_FRONT_END}/study-program/${formData.slug}` || '',
              synonyms: '',
              title:
                formData.seoTags && formData.seoTags.title
                  ? formData.seoTags.title
                  : '',
              titleWidth:
                formData.seoTags && formData.seoTags.title
                  ? formData.seoTags.title.length
                  : 0,
              url: formData.slug || '',
            });
            return <ContentAnalysis paper={paper} />;
          }}
        </FormDataConsumer>
      </FormTab>
      <FormTab label='Cấu hình'>
        <ReferenceInput
          source='studyProgram'
          reference='studyPrograms'
          label='Chương trình học'
          filterToQuery={(searchText) => ({ title: searchText })}
        >
          <AutocompleteInput optionText='title' />
        </ReferenceInput>
        <ReferenceInput
          source='courseLine'
          reference='courseLines'
          label='Course line'
          filterToQuery={(searchText) => ({ name: searchText })}
        >
          <AutocompleteInput optionText='name' />
        </ReferenceInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

import env from '../environments';

export const ID_URL = env.id.url;
export const ROOT_API = env.api.root;
export const ROOT_FRONT_END = env.resources.url;
export const WEB_URL = env.webUrl;

export const API_URL = `${ROOT_API}/api/v1`;
export const API_URL_ADMIN = `${API_URL}/admin`
export const API_URL_AUTH = `${API_URL}/auth`;
export const ROOT_RESOURCE_API = `${ROOT_FRONT_END}/api/v1`;
export const API_URL_UPLOAD = `${ROOT_RESOURCE_API}/resources`;
export const config = env.firebase;
export const GOOGLE_FONT = env.googleFont;
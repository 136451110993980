import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import debounce from 'lodash/debounce';

const style = { width: '100%' };

const StatInputs = ({ onChange, value }) => {
  const [data, setData] = useState(value || {});
  const handleOnItemChange = (value, type) => {
    const newItem = { ...data, [type]: value };
    setData(newItem);
    onChange(newItem);
  };
  const debounceFunc = debounce(handleOnItemChange, 500);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          style={style}
          defaultValue={data.stats}
          onChange={(e) => {
            debounceFunc(e.target.value, 'stats');
          }}
          label='Stats'
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          style={style}
          defaultValue={data.title}
          onChange={(e) => {
            debounceFunc(e.target.value, 'title');
          }}
          label='Title'
        />
      </Grid>
    </Grid>
  );
};

export default StatInputs;

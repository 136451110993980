import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { getToken } from '../../helpers';
import { API_URL_ADMIN } from '../../constant/url';
import { FormLabel } from '@material-ui/core';

const SingleSelectWithSearch = (props) => {
  const {
    onChange,
    initValue,
    reference,
    style,
    label,
    displayField,
    error,
  } = props;
  const [options, setOptions] = useState([]);
  useEffect(() => {
    handleLoadData('');
  }, []);
  const handleLoadData = async (searchString) => {
    const token = await getToken();
    const response = await fetch(
      `${API_URL_ADMIN}/${reference}?q=${searchString}`,
      {
        method: 'GET',
        headers: {
          'x-auth-token': token,
        },
      },
    );
    const data = await response.json();
    setOptions(data);
  };
  const handleChangeData = (value) => {
    onChange
      ? onChange(value._id || value)
      : props.input.onChange(value._id || value);
  };
  return (
    <>
      {label && (
        <FormLabel
          children={
            <div
              style={{
                marginBottom: '10px',
              }}
            >
              {label}
            </div>
          }
        />
      )}
      <Autocomplete
        id={`item-${initValue && (initValue._id || initValue)}`}
        style={style}
        labe
        size='small'
        getOptionSelected={(option) => option._id}
        defaultValue={initValue}
        options={options}
        filterOptions={(options) => options}
        getOptionLabel={(option) => option[displayField]}
        onChange={(e, value) => handleChangeData(value)}
        onKeyUp={(value) => {
          handleLoadData(value.target.value);
        }}
        renderInput={(params) => (
          <TextField error={error} helperText={error} {...params} />
        )}
      />
    </>
  );
};

export default SingleSelectWithSearch;

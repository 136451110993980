import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { addField } from 'ra-core';
import React, { useEffect, useState } from 'react';
import SingleSelectCourseWithSearch from '../SingleSelectCourseWithSearch';
import SortableList from '../SortableList';
import SortableRow from '../SortableRow';
import { Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SingleSelectWithSearch from '../SingleSelectWithSearch';
import { replace } from '../../helpers/array/replace';
import SingleSelectBlogPostWithSearch from '../SingleSelectBlogPostWithSearch';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { convertRefDataToIds } from './helper/convertRefDataToIds';
import { handleComponentChangeValue } from './helper/handleComponentChangeValue';
import UploadField from '../../customs/Upload';
import StatInputs from './Components/StatInputs';
import SellingPointsContentInput from './Components/SellingPointsContentInputs';

const validateStudyProgramValue = (values) => {
  const error = {};
  if (!values.name || (values.name && values.name.trim() === '')) {
    error.name = 'Name cannot be empty';
  }
  if (!values.courseCategoryRef) {
    error.courseCategoryRef = 'StudyProgram cannot be empty';
  }
  return error;
};

const HomePageConfigs = (props) => {
  const { initValue } = props;
  const [data, setData] = useState(initValue || {});

  useEffect(() => {
    const newData = convertRefDataToIds(data);
    props.input.onChange(newData);
  }, [data]);

  const handleSellingPointItemChange = (value, index, field) => {
    const newValue = {...data.sellingPoints[index], [field]: value};
    setData({
      ...(data || []),
      sellingPoints: replace(
        data.sellingPoints,
        index,
        newValue
      )
    });
  }

  const handleHighlightedStudyProgramNameChange = (value, index) => {
    const newValue = handleComponentChangeValue(data, 'name', value, index);
    setData({
      ...(data || []),
      highlightedStudyPrograms: replace(
        data.highlightedStudyPrograms,
        index,
        newValue,
      ),
    });
  };

  const handleStudyProgramRefChange = (value, index) => {
    const newValue = handleComponentChangeValue(
      data,
      'courseCategoryRef',
      value,
      index,
    );
    setData({
      ...(data || []),
      highlightedStudyPrograms: replace(
        data.highlightedStudyPrograms,
        index,
        newValue,
      ),
    });
  };
  const handleCourseListChange = (values, index) => {
    const newValue = handleComponentChangeValue(
      data,
      'courseRefs',
      values,
      index,
    );
    setData({
      ...(data || []),
      highlightedStudyPrograms: replace(
        data.highlightedStudyPrograms,
        index,
        newValue,
      ),
    });
  };
  const handleHighlightedEventBlogPostChange = (values) => {
    setData({
      ...(data || []),
      highlightedEventBlogPosts: values,
    });
  };
  const handleBannerVideoUrlChange = (value) => {
    setData({
      ...(data || []),
      bannerVideoUrl: value,
    });
  };
  const handleBannerVideoPosterImgUrlChange = (value) => {
    setData({
      ...(data || []),
      bannerVideoPosterImgUrl: value,
    });
  };
  const handleStatsChange = (value) => {
    setData({
      ...(data || []),
      facts: value,
    });
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          style={{ width: '256px' }}
          defaultValue={data.bannerVideoUrl}
          onChange={(e) => {
            handleBannerVideoUrlChange(e.target.value);
          }}
          label='Banner video url'
          variant='filled'
        />
      </Grid>
      <Grid item xs={12}>
        <UploadField
          input={{
            value: data.bannerVideoPosterImgUrl,
          }}
          meta={{}}
          onChange={handleBannerVideoPosterImgUrlChange}
          source='bannerVideoPosterImgUrl'
          label='Ảnh trước khi tải video'
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>Lộ trình học tập : </Typography>
      </Grid>
      <Grid item xs={12}>
        {data &&
          (data.highlightedStudyPrograms || []).map((values, index) => {
            return (
              <div
                style={{
                  marginLeft: '40px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <TextField
                    style={{
                      margin: '0 10px 20px 0',
                    }}
                    label='Name'
                    defaultValue={values.name}
                    error={validateStudyProgramValue(values).name}
                    onChange={(e) => {
                      handleHighlightedStudyProgramNameChange(
                        e.target.value,
                        index,
                      );
                    }}
                    helperText={validateStudyProgramValue(values).name}
                  />
                  <RemoveCircleOutlineIcon
                    onClick={() => {
                      setData({
                        ...data,
                        highlightedStudyPrograms: replace(
                          data.highlightedStudyPrograms,
                          index,
                        ),
                      });
                    }}
                    style={{ cursor: 'pointer', color: 'red' }}
                  />
                </div>
                <SingleSelectWithSearch
                  style={{
                    marginBottom: '20px',
                    width: '30%',
                  }}
                  initValue={values.courseCategoryRef}
                  label='Chọn lộ trình học tập'
                  onChange={(value) => {
                    handleStudyProgramRefChange(value, index);
                  }}
                  error={validateStudyProgramValue(values).courseCategoryRef}
                  reference='courseCategories'
                  displayField='name'
                />
                <SortableList
                  label='Các khóa học: '
                  initValues={values}
                  onChange={(data) => {
                    handleCourseListChange(data, index);
                  }}
                  SingleSelectComponent={SingleSelectCourseWithSearch}
                  ListItemComponent={SortableRow}
                  resource='courseRefs'
                  source='ref'
                />
              </div>
            );
          })}
      </Grid>
      <Grid item justify='flex-end' xs={12}>
        <AddCircleOutlineIcon
          onClick={() => {
            setData({
              ...data,
              highlightedStudyPrograms: [
                ...(data.highlightedStudyPrograms || []),
                {},
              ],
            });
          }}
          style={{ cursor: 'pointer', color: 'red' }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>Các bài viết nổi bật: </Typography>
      </Grid>
      <Grid item xs={12}>
        <SortableList
          initValues={data || []}
          onChange={(data) => {
            if (data.length >= 0) {
              handleHighlightedEventBlogPostChange(data);
            }
          }}
          SingleSelectComponent={SingleSelectBlogPostWithSearch}
          ListItemComponent={SortableRow}
          resource='highlightedEventBlogPosts'
          source='BlogPosts'
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>Những điều chỉ mindx cung cấp : </Typography>
      </Grid>
      <Grid item xs={12}>
        {data &&
          (data.sellingPoints || []).map((values, index) => {
            return (
              <div
                style={{
                  marginLeft: '40px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <TextField
                    style={{
                      margin: '0 10px 20px 0',
                    }}
                    label='Title'
                    defaultValue={values.title}
                    onChange={(e) => {
                      handleSellingPointItemChange(
                        e.target.value,
                        index,
                        'title'
                      );
                    }}
                  />
                  <UploadField
                    input={{
                      value: values.imageUrl,
                    }}
                    meta={{}}
                    onChange={(imageUrl) => handleSellingPointItemChange(imageUrl, index, 'imageUrl')}
                    source='sellingPoints.imageUrl'
                    label='Ảnh'
                  />
                  <RemoveCircleOutlineIcon
                    onClick={() => {
                      setData({
                        ...data,
                        sellingPoints: replace(
                          data.sellingPoints,
                          index,
                        ),
                      });
                    }}
                    style={{ cursor: 'pointer', color: 'red' }}
                  />
                </div>
                <SortableList
                  label='Nội dung'
                  initValues={values}
                  onChange={(data) => {
                    handleSellingPointItemChange(data, index, 'content');
                  }}
                  SingleSelectComponent={SellingPointsContentInput}
                  ListItemComponent={SortableRow}
                  resource='content'
                  source='content'
                />
              </div>
            );
          })}
      </Grid>
      <Grid item justify='flex-end' xs={12}>
        <AddCircleOutlineIcon
          onClick={() => {
            setData({
              ...data,
              sellingPoints: [
                ...(data.sellingPoints || []),
                {},
              ],
            });
          }}
          style={{ cursor: 'pointer', color: 'red' }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>Số liệu thống kê: </Typography>
      </Grid>
      <Grid item xs={12}>
        <SortableList
          initValues={data || []}
          onChange={handleStatsChange}
          SingleSelectComponent={StatInputs}
          ListItemComponent={SortableRow}
          resource='facts'
          source='facts'
        />
      </Grid>
    </Grid>
  );
};

export default addField(HomePageConfigs);

import React from 'react';
import {
  List,
  required,
  Datagrid,
  Create,
  SimpleForm,
  TextInput,
  TextField,
  FormTab,
  Filter,
  Edit,
  TabbedForm,
} from 'react-admin';
import UploadField from '../customs/Upload';

const CandidateFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
  </Filter>
);

export const CandidateList = (props) => {
  return (
    <List
      {...props}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      filters={<CandidateFilter />}
    >
      <Datagrid rowClick='edit'>
        <TextField label='Tên ứng viên' source='name' />
        <TextField label='Slug' source='slug' />
      </Datagrid>
    </List>
  );
};

export const CandidateEdit = (props) => {
  return (
    <Edit {...props} title='Edit Candidate'>
      <TabbedForm>
        <FormTab label='Thông tin cuộc thi'>
          <TextInput source='name' label='Tên ứng viên' validate={required()} />
          <TextInput source='slug' label='Slug' validate={required()} />
          <UploadField source='avatarUrl' label='Avatar' />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const CandidateCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect='list'>
      <TextInput source='name' label='Tên ứng viên' validate={required()} />
      <TextInput source='slug' label='Slug' validate={required()} />
      <UploadField source='avatarUrl' label='Avatar' />
    </SimpleForm>
  </Create>
);

import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  TextInput,
  Filter,
} from 'react-admin';

const MethodFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
  </Filter>
);

export const BaseCourseList = (props) => (
  <List {...props} filters={<MethodFilter />}>
    <Datagrid>
      <TextField source='name' label='Tên' />
      <TextField source='shortName' label='Tên rút gọn' />
      <BooleanField source='isActive' label='Tình trạng hoạt động' />
    </Datagrid>
  </List>
);

import FormLabel from '@material-ui/core/FormLabel';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { addField } from 'ra-core';
import React, { useEffect, useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { replace } from '../../helpers/array/replace';
import { swap } from '../../helpers/array/swap';

const SortableItem = SortableElement((props) => {
  const {
    ListItemComponent,
    value,
    onItemChange,
    onDeleteItem,
    SingleSelectComponent,
  } = props;
  return (
    <li
      style={{
        maxWidth: '31rem',
        width: '60%',
        zIndex: '9999',
        listStyle: 'none',
      }}
    >
      <ListItemComponent
        value={value}
        onDeleteItem={onDeleteItem}
        onItemChange={onItemChange}
        SingleSelectComponent={SingleSelectComponent}
      />
    </li>
  );
});

const List = SortableContainer((props) => {
  const {
    ListItemComponent,
    items,
    referenceSource,
    onItemChange,
    onItemDeleted,
    SingleSelectComponent,
  } = props;
  return (
    <ul
      className='list-sortableItems'
      style={{
        listStyle: 'none',
        marginTop: '-8px',
        marginBottom: '-8px',
      }}
    >
      {items.map((value, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          value={value}
          ListItemComponent={ListItemComponent}
          SingleSelectComponent={SingleSelectComponent}
          referenceSource={referenceSource}
          onItemChange={(value) => {
            onItemChange(value, index);
          }}
          onDeleteItem={() => {
            onItemDeleted(index);
          }}
        />
      ))}
    </ul>
  );
});

const SortableList = (props) => {
  const {
    ListItemComponent,
    initValues,
    resource,
    isHideCreate = false,
    label,
    SingleSelectComponent,
    onChange,
  } = props;

  const [listData, setListData] = useState(initValues[resource] || []);
  useEffect(() => {
    const newData = listData
      .filter((item) => item)
      .map((item) => item._id || item);
    if (onChange) {
      onChange(newData);
    }
    props.input.onChange(newData);
  }, [listData]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newListData = swap(listData, oldIndex, newIndex);
    setListData(newListData);
  };

  const handleItemDeleted = (index) => {
    const newData = replace(listData, index);
    setListData(newData);
  };
  const handleItemChange = (value, index) => {
    const newData = replace(listData, index, value);
    setListData(newData);
  };
  return (
    <>
      {label && (
        <FormLabel
          children={
            <div
              style={{
                marginBottom: '10px',
              }}
            >
              {label}
            </div>
          }
        />
      )}
      <List
        items={listData}
        onSortEnd={onSortEnd}
        SingleSelectComponent={SingleSelectComponent}
        onItemChange={handleItemChange}
        onItemDeleted={handleItemDeleted}
        distance={1}
        ListItemComponent={ListItemComponent}
      />
      {!isHideCreate && (
        <AddCircleOutlineIcon
          onClick={() => {
            setListData([...listData, null]);
          }}
          style={{ cursor: 'pointer', margin: '10px 0 0 40px' }}
        />
      )}
    </>
  );
};
export default addField(SortableList);

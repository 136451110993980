import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { addField, FieldTitle } from 'ra-core';
import { SketchPicker } from 'react-color';

export class ColorInput extends Component {
  state = {
    displayColorPicker: false,
  };

  handleChange = (eventOrValue) => {
    this.props.onChange(eventOrValue.hex || '');
    this.props.input.onChange(eventOrValue.hex || '');
  };

  render() {
    const {
      className,
      input,
      isRequired,
      label,
      meta,
      options,
      resource,
      source,
      type,
      ...rest
    } = this.props;
    if (typeof meta === 'undefined') {
      throw new Error(
        "The ColorInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details.",
      );
    }
    const { touched, error } = meta;

    const color = input.value || '#000000';

    return (
      <div>
        <div
          style={{
            paddingBottom: '10px',
            fontSize: '0.75rem',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            color: 'rgba(0, 0, 0, 0.54)',
          }}
        >
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        </div>
        <div
          style={{
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
          }}
          onClick={() => {
            this.setState({
              displayColorPicker: !this.state.displayColorPicker,
            });
          }}
        >
          <div
            style={{
              width: '36px',
              height: '14px',
              borderRadius: '2px',
              background: color,
            }}
          />
        </div>
        {this.state.displayColorPicker ? (
          <div
            style={{
              position: 'absolute',
              zIndex: '2',
            }}
          >
            <div
              style={{
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
              }}
              onClick={() => {
                this.setState({
                  displayColorPicker: false,
                });
              }}
            />
            <SketchPicker color={color} onChange={this.handleChange} />
          </div>
        ) : null}
      </div>
    );
  }
}

ColorInput.propTypes = {
  className: PropTypes.string,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  meta: PropTypes.object,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  type: PropTypes.string,
};

ColorInput.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  options: {},
  type: 'text',
};

export default addField(ColorInput);

import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';
import classnames from 'classnames';

const styles = theme => ({
  menuItem: {
    color: theme.palette.text.secondary,
  },
  iconMenuPaddingStyle: {
    paddingRight: '1.2em',
  },
  iconPaddingStyle: {
    paddingRight: theme.spacing.unit,
  },
});

const sanitizeRestProps = ({
  classes,
  className,
  userLogout,
  locale,
  redirectTo,
  ...rest
}) => rest;

const Logout = ({
  classes,
  className,
  logoutAction,
  ...rest
}) => (
  <MenuItem
    className={classnames('logout', classes.menuItem, className)}
    onClick={logoutAction}
    {...sanitizeRestProps(rest)}
  >
    <span className={classes.iconMenuPaddingStyle}>
      <ExitIcon />
    </span>
    Logout
  </MenuItem>
);

Logout.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  classnames: PropTypes.string,
  logoutAction: PropTypes.func.isRequired,
  redirectTo: PropTypes.string,
};

export default withStyles(styles)(Logout);

import { Grid } from '@material-ui/core';
import DehazeIcon from '@material-ui/icons/Dehaze';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import './style.css';
const SortableRow = (props) => {
  const { value, onItemChange, onDeleteItem, SingleSelectComponent } = props;
  return (
    <Grid container spacing={3}>
      <Grid item xs={10}>
        <SingleSelectComponent value={value} onChange={onItemChange} />
      </Grid>
      <Grid
        item
        xs={1}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <DehazeIcon
          style={{
            cursor: 'pointer',
          }}
        />
      </Grid>
      <Grid
        item
        xs={1}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <DeleteIcon
          onClick={onDeleteItem}
          style={{
            cursor: 'pointer',
            color: '#f44336',
          }}
        />
      </Grid>
    </Grid>
  );
};
export default SortableRow;

import { AutocompleteInput, ReferenceInput } from 'react-admin';
import React from 'react';

export const SingleCourseSelect = (props) => {
  return (
    <ReferenceInput
      {...props}
      reference='courses'
      filterToQuery={(search) => {
        return {
          q: search,
        };
      }}
      allowEmpty={true}
    >
      <AutocompleteInput variant='standard' optionText='name' emptyText='-- Empty --' />
    </ReferenceInput>
  );
};

export const getRelativePath = (url) => {
  let relativePath = url;
  const isUrl = /(http|https)\:\/\//.test(relativePath);
  
  if (isUrl) {
    relativePath = relativePath.replace(/^(?:\/\/|[^/]+)*\//, '');
  }
  relativePath = relativePath.split('?')[0];
  
  return relativePath;
};

import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  BooleanField,
  Create,
  Edit,
  TextInput,
  SimpleForm,
  Filter,
  NumberInput,
  BooleanInput,
} from 'react-admin';
import UploadField from '../customs/Upload';


const MethodFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const PartnerCompaniesList = (props) => {
  return (
    <List {...props} filters={<MethodFilter />}>
      <Datagrid rowClick='edit'>
        <TextField source='name' label='Tên công ty' />
        <NumberField source='order' label='Thứ tự logo' />
        <BooleanField source='isActive' label='Đang hoạt động' />
      </Datagrid>
    </List>
  );
};

export const PartnerCompaniesCreate = (props) => (
         <Create {...props}>
           <SimpleForm redirect='list'>
             <TextInput source='name' label='Tên công ty' />
             <BooleanInput
               source='isActive'
               label='Đang hoạt động'
               defaultValue={true}
             />
             <NumberInput source='logoHeight' label='chiều cao logo' />
             <NumberInput source='order' label='Thứ tự logo' />
             <UploadField source='logoUrl' label='logo' />
           </SimpleForm>
         </Create>
       );

export const PartnerCompaniesEdit = (props) => (
         <Edit {...props} title='Edit Company'>
           <SimpleForm redirect='list'>
             <TextInput source='name' label='Tên công ty' />
             <BooleanInput
               source='isActive'
               label='Đang hoạt động'
             />
             <NumberInput source='logoHeight' label='cỡ logo' />
             <NumberInput source='order' label='Thứ tự logo' />
             <UploadField source='logoUrl' label='logo' />
           </SimpleForm>
         </Edit>
       );

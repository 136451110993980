import React from 'react';
import { Layout as RALayout } from 'react-admin';
import { createMenu } from './Menu';

import {
  School,
  Person,
  CameraRoll,
  CollectionsBookmark,
  Stars,
  LibraryBooks,
  Label,
  Assignment,
  Class,
  Language,
  Phone,
  Room,
  Inbox,
  Build,
  Business,
  EmojiEvents,
  Image,
  Theaters,
  Sort,
  Loyalty,
  MeetingRoom,
  AssignmentInd,
  Shop,
  Settings,
  Ballot,
  PostAdd,
  CallSplit,
  ChildCare, SettingsApplications, AccountTree,
} from '@material-ui/icons';

const menuItems = [
  {
    icon: <Person />,
    text: 'Đơn đăng ký',
    link: '/registrations',
  },
  {
    icon: <Build />,
    text: 'Thông tin chung',
    items: [
      {
        icon: <Language />,
        text: 'Vùng kinh doanh',
        link: '/regions',
      },
      {
        icon: <Room />,
        text: 'Cơ sở',
        link: '/locations',
      },
      {
        icon: <Phone />,
        text: 'Hotline chung',
        link: '/generalHotlines',
      },
    ],
  },
  {
    icon: <Assignment />,
    text: 'Blog',
    items: [
      {
        icon: <LibraryBooks />,
        text: 'Blog posts',
        link: '/blogPosts',
      },
      {
        icon: <CameraRoll />,
        text: 'Câu chuyện mindX',
        link: '/techkidsStories',
      },
      {
        icon: <CollectionsBookmark />,
        text: 'Blog categories',
        link: '/blogCategories',
      },
      {
        icon: <Label />,
        text: 'Tags',
        link: '/tags',
      },
    ],
  },
  {
    icon: <Business />,
    text: 'Trang chủ',
    items: [
      {
        icon: <Image />,
        text: 'Banner trang chủ',
        link: '/homeSlides',
      },
      {
        icon: <Theaters />,
        text: 'Trên truyền thông',
        link: '/mediaAppearances',
      },
      {
        icon: <Business />,
        text: 'Công ty đối tác',
        link: '/partnerCompanies',
      },
      {
        icon: <AssignmentInd />,
        text: 'Học viên nổi bật',
        link: '/honoredStudents',
      },
      {
        icon: <Stars />,
        text: 'Sản phẩm nổi bật',
        link: '/outstandResults',
      },
    ],
  },
  {
    icon: <EmojiEvents />,
    text: 'Cuộc thi',
    items: [
      {
        icon: <School />,
        text: 'Bài dự thi',
        link: '/submissions',
      },
      {
        icon: <EmojiEvents />,
        text: 'Các cuộc thi',
        link: '/contests',
      },
      // {
      //   icon: <ChildCare />,
      //   text: 'Các thí sinh',
      //   link: '/candidates',
      // },
    ],
  },
  {
    icon: <Inbox />,
    text: 'Khóa học',
    items: [
      {
        icon: <Class />,
        text: 'Các khóa học',
        link: '/courses',
      },
      {
        icon: <Loyalty />,
        text: 'Chương trình học',
        link: '/studyPrograms',
      },
      {
        icon: <Shop />,
        text: 'Các sản phẩm',
        link: '/products',
      },
      {
        icon: <Sort />,
        text: 'Phân loại khóa học',
        link: '/courseCategories',
      },
      {
        icon: <MeetingRoom />,
        text: 'Các lớp học',
        link: '/classes',
      },
    ],
  },
  {
    icon: <Person />,
    text: 'Người dùng',
    link: '/users',
  },
  {
    icon: <Settings />,
    text: 'Cấu hình',
    link: '/settings',
  },
  {
    icon: <AccountTree />,
    text: '3rd party content config ',
    link: '/thirdPartyContent',
  },
  {
    icon: <Ballot />,
    text: 'Menu trang',
    link: '/menuItems',
  },
  {
    icon: <PostAdd />,
    text: 'Cấu hình trang',
    link: '/pageConfigs',
  },
  {
    icon: <CallSplit />,
    text: 'Chuyển tiếp Slug',
    link: '/slugRedirect',
  },
];

const Layout = (props) => <RALayout {...props} menu={createMenu(menuItems)} />;

export default Layout;

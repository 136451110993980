import firebase from 'firebase';

export const logout = async (done) => {
  await firebase.auth().signOut();
  done()
}

export const getToken = () => {
  return new Promise(async (resolve, reject) => {
    firebase.auth().onAuthStateChanged(async (userInfo, error) => {
      if (userInfo) {
        const { expirationTime, token } = await firebase.auth().currentUser.getIdTokenResult();
        if (new Date(expirationTime).valueOf() <= (new Date().valueOf() - 60*1000)) {
          const newToken = await firebase.auth().currentUser.getIdToken(true);
          resolve(newToken);
        } else resolve(token);
      } else {
        reject();
      };
    });
  });
};

import React from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  NumberField,
  SingleFieldList,
  ChipField,
  ReferenceArrayField,
  Create,
  Edit,
  TextInput,
  BooleanInput,
  SimpleForm,
  Filter,
  ArrayInput,
  SimpleFormIterator,
  ReferenceArrayInput,
  downloadCSV,
} from "react-admin";
import jsonExport from 'jsonexport/dist';
import get from 'lodash/get';
import "antd/dist/antd.css";
import { Spin } from "antd";
import CheckBoxArrayInput from "../customs/CheckBoxArrayInput";
import UploadField from "../customs/Upload";

const MethodFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

const exporter = (submissions, fetchRelatedRecords) => {
  fetchRelatedRecords(submissions, 'contestsRef', 'contests').then(contests => {
    const submissionForExport = submissions.map(post => {
      const { title, likeCount, contestsRef, ...restOfPost } = post;
      const contestId = get(contestsRef, '0');
      return {
        Title: title,
        Likes: likeCount,
        Contest: get(contests, `${contestId}.title`),
      };
    });
    jsonExport(submissionForExport, {
        headers: ['Title', 'Likes', 'Contest'] // order fields in the export
    }, (err, csv) => {
        downloadCSV(csv, 'submissions'); // download as 'posts.csv` file
    });
  });
};

export const SubmissionsList = props => {
  return (
    <List {...props} filters={<MethodFilter />} exporter={exporter}>
      <Datagrid rowClick="edit">
        <TextField source="title" label="Tiêu đề" />
        <BooleanField source="isActive" label="Đang hoạt động" />
        <NumberField source='likeCount' label='Số like' />
        <ReferenceArrayField source='contestsRef' reference="contests" label='Cuộc thi'>
          <SingleFieldList linkType={false}>
            <ChipField source='title' />
          </SingleFieldList>
        </ReferenceArrayField>
      </Datagrid>
    </List>
  );
};
export class SubmissionsCreate extends React.Component {
  state = {
    listStudentsSelected: [],
    listClassFromLms: [],
    listNameClass: [],
    typeOfAddStudent: "class",
    listInfoStudentsSearchByQuery: [],
    isSearching: false
  };

  debounce = (func, wait, immediate, value) => {
    var timeout;
    return function() {
      var context = value,
        args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  render() {
    const { isSearching } = this.state;
    return (
      <Create {...this.props}>
        <SimpleForm redirect="list">
          <Spin tip="Loading..." spinning={isSearching}>
            <TextInput source="title" label="Tiêu đề" />
            <TextInput source="description" label="Mô tả" />
            <BooleanInput source="isActive" label="Đang hoạt động" initialValue={true} />
            <UploadField source="coverImage" label="Ảnh bài thi" />
            <ArrayInput source="images" label="Ảnh mô tả">
              <SimpleFormIterator>
                <UploadField source="" label="Ảnh" />
              </SimpleFormIterator>
            </ArrayInput>
            <TextInput
              style={{ display: "none" }}
              source="users"
              defaultValue={this.state.listStudentsSelected}
              label="Test"
            />
            <TextInput source="video" label="Video url" />
            <ReferenceArrayInput
              label="Cuộc thi"
              source="contestsRef"
              reference="contests"
              filter={{ all: true }}
            >
              <CheckBoxArrayInput optionText="title" optionValue="_id" />
            </ReferenceArrayInput>
          </Spin>
        </SimpleForm>
      </Create>
    );
  }
}

export class SubmissionsEdit extends React.Component {
  state = {
    listStudentsSelected: [],
    listClassFromLms: [],
    listNameClass: [],
    typeOfAddStudent: "class",
    listNameOfStudentsSearchByQuery: [],
    listInfoStudentsSearchByQuery: [],
    isSearching: false,
    countFetch: 0
  };
  
  render() {
    const { isSearching } = this.state;
    return (
      <Edit {...this.props} title="Edit Story">
        <SimpleForm>
          <Spin tip="Loading..." spinning={isSearching}>
            <TextInput source="title" label="Tiêu đề" />
            <TextInput source="description" label="Mô tả" />
            <BooleanInput source="isActive" label="Đang hoạt động" />
            <UploadField source="coverImage" label="Ảnh bài thi" />
            <TextInput source="video" label="Video url" />
            <ReferenceArrayInput
              label="Cuộc thi"
              source="contestsRef"
              reference="contests"
              filter={{ all: true }}
            >
              <CheckBoxArrayInput optionText="title" optionValue="_id" />
            </ReferenceArrayInput>
          </Spin>
        </SimpleForm>
      </Edit>
    );
  }
}

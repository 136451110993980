import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  TextInput,
  ReferenceField,
  SimpleForm,
  ArrayInput,
  SimpleFormIterator,
  ChipField,
  Filter,
  FormDataConsumer,
} from 'react-admin';
import UploadField from '../customs/Upload';
import InputWithSlug from '../customs/InputWithSlug';
import { SingleCourseSelect } from '../customs/SingleCourseSelect';

const MethodFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
  </Filter>
);

export const OutstandResultList = (props) => (
  <List {...props} filters={<MethodFilter />}>
    <Datagrid rowClick='edit'>
      <TextField source='title' label='Tên sản phẩm' />
      <TextField source='timeSpent' label='Thời gian thực hiện' />
      <ReferenceField source='courseRef' label='Khoá học' reference='courses'>
        <ChipField source='name' />
      </ReferenceField>
    </Datagrid>
  </List>
);

export const OutstandResultCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect='list'>
      <FormDataConsumer>
        {(formData) => {
          return (
            <InputWithSlug
              initValue={formData.formData}
              source='title'
              resourceId={props.id}
              name='title'
              subApi='outStandResults'
              label='Title'
            />
          );
        }}
      </FormDataConsumer>
      <TextInput source='timeSpent' label='Thời gian thực hiện' />
      <TextInput multiline source='description' label='Mô tả ngắn' />
      <SingleCourseSelect label='Khoá học' source='courseRef' />
      <ArrayInput source='authors' label='Thành viên thực hiện'>
        <SimpleFormIterator>
          <TextInput source='' />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source='achievement' label='Thành tích'>
        <SimpleFormIterator>
          <TextInput source='' />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput
        source='videoIntro'
        label='Url nhúng(embed) video giới thiệu'
      />
      <ArrayInput source='images' label='Ảnh mô tả'>
        <SimpleFormIterator>
          <UploadField source='' label='Ảnh' />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);

export const OutstandResultEdit = (props) => (
  <Edit {...props} title='Edit Outstand Result'>
    <SimpleForm>
      <FormDataConsumer>
        {(formData) => {
          return (
            <InputWithSlug
              initValue={formData.formData}
              source='title'
              resourceId={props.id}
              name='title'
              subApi='outStandResults'
              label='Title'
            />
          );
        }}
      </FormDataConsumer>
      <TextInput source='timeSpent' label='Thời gian thực hiện' />
      <TextInput multiline source='description' label='Mô tả ngắn' />
      <SingleCourseSelect label='Khoá học' source='courseRef' />
      <ArrayInput source='authors' label='Thành viên thực hiện'>
        <SimpleFormIterator>
          <TextInput source='' />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source='achievement' label='Thành tích'>
        <SimpleFormIterator>
          <TextInput source='' />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput
        source='videoIntro'
        label='Url nhúng(embed) video giới thiệu'
      />
      <ArrayInput source='images' label='Ảnh mô tả'>
        <SimpleFormIterator>
          <UploadField source='' label='Ảnh' />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

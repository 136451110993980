export const convertRefDataToIds = (data) => {
  const newData = { ...data };
  if (
    newData.highlightedEventBlogPosts &&
    newData.highlightedEventBlogPosts.length > 0
  ) {
    newData.highlightedEventBlogPosts = newData.highlightedEventBlogPosts.map(
      (item) => item._id || item,
    );
  }
  if (
    newData.highlightedStudyPrograms &&
    newData.highlightedStudyPrograms.length > 0
  ) {
    newData.highlightedStudyPrograms = newData.highlightedStudyPrograms.map(
      (highlightedStudyProgram) => {
        const newHighlightedStudyProgram = { ...highlightedStudyProgram };
        if (newHighlightedStudyProgram.courseCategoryRef) {
          newHighlightedStudyProgram.courseCategoryRef =
            newHighlightedStudyProgram.courseCategoryRef._id ||
            newHighlightedStudyProgram.courseCategoryRef;
        }
        if (
          newHighlightedStudyProgram.courseRefs &&
          newHighlightedStudyProgram.courseRefs.length > 0
        ) {
          newHighlightedStudyProgram.courseRefs = newHighlightedStudyProgram.courseRefs.map(
            (item) => item._id || item,
          );
        }
        return newHighlightedStudyProgram;
      },
    );
  }
  return newData;
};

import React, { useState } from 'react';
import { Admin, Resource } from 'react-admin';

// ICONS
import {
  School,
  Person,
  CameraRoll,
  CollectionsBookmark,
  Stars,
  LibraryBooks,
  Label,
  Class,
  ViewList,
  Language,
  Phone,
  Room,
  Settings,
  Ballot,
  Adjust,
  CallSplit,
  Business, BusinessCenter, AccountTree,
} from '@material-ui/icons';

import Login from './components/Login';
import Layout from './components/Layout';

import {
  CategoryList,
  CategoryEdit,
  CategoryCreate,
} from './resources/blogCategories';

import { PostList, PostEdit, PostCreate } from './resources/blogPosts';
import { UserList, UserEdit } from './resources/users';
import { TagList, TagEdit, TagCreate } from './resources/tags';
import {
  HonoredStudentsList,
  HonoredStudentsCreate,
  HonoredStudentsEdit,
} from './resources/honoredStudents';
import { CoursesList, CoursesEdit, CoursesCreate } from './resources/courses';
import { ProductsList } from './resources/products';
import {
  CourseCategoriesList,
  CourseCategoriesEdit,
} from './resources/courseCategories';
import { ClassesList, ClassesEdit } from './resources/classes';
import {
  ContestsList,
  ContestsEdit,
  ContestsCreate,
} from './resources/contests';
import {
  CandidateList,
  CandidateCreate,
  CandidateEdit,
} from './resources/candidates';
import {
  TechkidsStoriesCreate,
  TechkidsStoriesEdit,
  TechkidsStoriesList,
} from './resources/techkidsStories';
import {
  SubmissionsCreate,
  SubmissionsEdit,
  SubmissionsList,
} from './resources/submissions';
import {
  OutstandResultList,
  OutstandResultCreate,
  OutstandResultEdit,
} from './resources/outstandResults';
import {
  MediaAppearancesList,
  MediaAppearancesCreate,
  MediaAppearancesEdit,
} from './resources/mediaAppearances';
import {
  HomeSlideCreate,
  HomeSlideEdit,
  HomeSlideList,
} from './resources/homeSlides';

import {
  StudyProgramList,
  StudyProgramCreate,
  StudyProgramEdit,
} from './resources/studyProgram';
import { LocationList, LocationEdit } from './resources/location';
import {
  GeneralHotlineList,
  GeneralHotlineEdit,
  GeneralHotlineCreate,
} from './resources/generalHotlines';
import { RegionList, RegionEdit, RegionCreate } from './resources/region';
import { RegistrationList, RegistrationShow } from './resources/registation';
import { SettingsList, SettingsEdit } from './resources/settings';
import {
  MenuItemList,
  MenuItemCreate,
  MenuItemEdit,
} from './resources/menuItems';
import { BaseCourseList } from './resources/baseCourses';

import dataProvider from './provider/dataProvider';
import authProvider from './provider/authProvider';
import LogoutButton from './logoutbutton';
import './App.css';

import { getToken, logout } from './helpers';
import {
  PageConfigCreate,
  PageConfigEdit,
  PageConfigList,
} from './resources/pageConfigs';

import {
  SlugRedirectEdit,
  SlugRedirectList,
  SlugRedirectCreate,
} from './resources/slugRedirect';
import {
  PartnerCompaniesList,
  PartnerCompaniesEdit,
  PartnerCompaniesCreate,
} from './resources/partnerCompanies'
import {
  ThirdPartyContentCreate,
  ThirdPartyContentEdit,
  ThirdPartyContentList
} from './resources/thirdPartyContent';

const getResourceList = (permission) => {
  return permission === 'admin'
    ? [
        <Resource
          name='registrations'
          icon={Person}
          options={{ label: 'Đơn đăng ký' }}
          list={RegistrationList}
          show={RegistrationShow}
        />,
        <Resource
          name='regions'
          icon={Room}
          options={{ label: 'Vùng kinh doanh' }}
          list={RegionList}
          edit={RegionEdit}
          create={RegionCreate}
        />,
        <Resource
          name='locations'
          icon={Language}
          options={{ label: 'Cơ sở' }}
          list={LocationList}
          edit={LocationEdit}
        />,
        <Resource
          name='generalHotlines'
          icon={Phone}
          options={{ label: 'Hotline chung' }}
          list={GeneralHotlineList}
          edit={GeneralHotlineEdit}
          create={GeneralHotlineCreate}
        />,
        <Resource
          name='blogCategories'
          icon={CollectionsBookmark}
          options={{ label: 'Blog Categories' }}
          list={CategoryList}
          create={CategoryCreate}
          edit={CategoryEdit}
        />,
        <Resource
          name='blogPosts'
          icon={LibraryBooks}
          options={{ label: 'Blog Posts' }}
          list={PostList}
          create={PostCreate}
          edit={PostEdit}
        />,
        <Resource
          name='tags'
          icon={Label}
          options={{ label: 'Tags' }}
          list={TagList}
          create={TagCreate}
          edit={TagEdit}
        />,
        <Resource
          name='homeSlides'
          icon={CameraRoll}
          options={{ label: 'Banner trang chủ' }}
          list={HomeSlideList}
          create={HomeSlideCreate}
          edit={HomeSlideEdit}
        />,
        <Resource
          name='techkidsStories'
          icon={CameraRoll}
          options={{ label: 'Câu chuyện Techkids' }}
          list={TechkidsStoriesList}
          create={TechkidsStoriesCreate}
          edit={TechkidsStoriesEdit}
        />,
        <Resource
          name='submissions'
          icon={School}
          options={{ label: 'Bài dự thi' }}
          list={SubmissionsList}
          create={SubmissionsCreate}
          edit={SubmissionsEdit}
        />,
        <Resource
          name='mediaAppearances'
          icon={CameraRoll}
          options={{ label: 'Truyền thông' }}
          list={MediaAppearancesList}
          create={MediaAppearancesCreate}
          edit={MediaAppearancesEdit}
        />,
        <Resource
          name='honoredStudents'
          icon={School}
          options={{ label: 'Học viên' }}
          list={HonoredStudentsList}
          create={HonoredStudentsCreate}
          edit={HonoredStudentsEdit}
        />,
        <Resource
          name='outstandResults'
          icon={Stars}
          options={{ label: 'Sản phẩm' }}
          list={OutstandResultList}
          create={OutstandResultCreate}
          edit={OutstandResultEdit}
        />,
        <Resource
          name='studyPrograms'
          icon={ViewList}
          options={{ label: 'Chương trình học' }}
          list={StudyProgramList}
          create={StudyProgramCreate}
          edit={StudyProgramEdit}
        />,
        <Resource name='courseLines' />,
        <Resource
          name='courseCategories'
          icon={ViewList}
          options={{ label: 'Cụm khoá học' }}
          list={CourseCategoriesList}
          edit={CourseCategoriesEdit}
        />,
        <Resource
          name='courses'
          icon={Stars}
          options={{ label: 'Khoá học' }}
          list={CoursesList}
          edit={CoursesEdit}
          create={CoursesCreate}
        />,
        <Resource
          name='products'
          icon={Stars}
          options={{ label: 'Sản phẩm' }}
          list={ProductsList}
        />,
        <Resource
          name='classes'
          icon={Class}
          options={{ label: 'Lớp học' }}
          list={ClassesList}
          edit={ClassesEdit}
        />,
        <Resource
          name='contests'
          icon={Language}
          options={{ label: 'Cuộc thi' }}
          list={ContestsList}
          edit={ContestsEdit}
          create={ContestsCreate}
        />,
        <Resource
          name='candidates'
          icon={Language}
          options={{ label: 'Thí sinh' }}
          list={CandidateList}
          edit={CandidateEdit}
          create={CandidateCreate}
        />,
        <Resource
          name='users'
          icon={Person}
          options={{ label: 'Người dùng' }}
          list={UserList}
          edit={UserEdit}
        />,
        <Resource
          name='settings'
          icon={Settings}
          options={{ label: 'Cấu hình' }}
          list={SettingsList}
          edit={SettingsEdit}
        />,
        <Resource
          name='menuItems'
          icon={Ballot}
          options={{ label: 'Menu trang' }}
          list={MenuItemList}
          create={MenuItemCreate}
          edit={MenuItemEdit}
        />,
        <Resource
          name='baseCourses'
          icon={Adjust}
          options={{ label: 'Các khoá học từ BASE' }}
          list={BaseCourseList}
        />,
        <Resource
          name='pageConfigs'
          options={{ label: 'Cấu hình trang' }}
          list={PageConfigList}
          create={PageConfigCreate}
          edit={PageConfigEdit}
        />,
        <Resource
          name='slugRedirect'
          icon={CallSplit}
          options={{ label: 'Chuyển tiếp Slug' }}
          list={SlugRedirectList}
          edit={SlugRedirectEdit}
          create={SlugRedirectCreate}
        />,
        <Resource
          name='partnerCompanies'
          icon={Business}
          options={{ label: 'Công ty hợp tác' }}
          list={PartnerCompaniesList}
          create={PartnerCompaniesCreate}
          edit={PartnerCompaniesEdit}
        />,
      <Resource
        name='thirdPartyContent'
        icon={AccountTree}
        options={{ label: 'third party content config' }}
        list={ThirdPartyContentList}
        create={ThirdPartyContentCreate}
        edit={ThirdPartyContentEdit}
      />,
      ]
    : [];
};

const AppComponent = (props) => {
  const { setLoggedIn } = props;
  return (
    <Admin
      layout={Layout}
      dataProvider={dataProvider(getToken)}
      authProvider={(type, params) =>
        authProvider(type, params, getToken, setLoggedIn)
      }
      logoutButton={() => (
        <LogoutButton logoutAction={() => logout(() => setLoggedIn(false))} />
      )}
    >
      {getResourceList}
    </Admin>
  );
};

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  return (
    <>
      {loggedIn ? (
        <AppComponent setLoggedIn={setLoggedIn} />
      ) : (
        <Login setLoggedIn={setLoggedIn} />
      )}
    </>
  );
};

export default App;

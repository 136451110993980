import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FieldTitle } from 'react-admin';
import _ from 'lodash';
import { AnalysisWorkerWrapper } from 'yoastseo';
import { CircularProgress } from '@material-ui/core';

import formatAnalyzeResult from '../../utils/formatAnalyzeResult';
import AnalysisWebWorker from '../../analysis.worker';

import Results from './Results';

const worker = new AnalysisWorkerWrapper(new AnalysisWebWorker());

const styles = {
  root: {
    fontFamily: 'arial,sans-serif',
    margin: '16px 0 8px',
  },
	fieldTitle: {
		paddingBottom: '10px',
		fontSize: '0.75rem',
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
		color: 'rgba(0, 0, 0, 0.54)'
	}
};

class ContentAnalysis extends Component {
	state = {
		result: {},
		loading: true,
	}

	analyzeContent = () => {
		const { result, loading } = this.state;
		const { paper } = this.props;
		
		if(paper) {
			worker.analyze(paper)
				.then(analyzeResult => {
					const newResult = formatAnalyzeResult( analyzeResult.result, "" );
					if(!_.isEqual(newResult, result)) {
						this.setState({ result: newResult, loading: false });
					}
				}).catch(error => console.log(error));
		} else if(loading) this.setState({ result: null, loading: false });
	}

	async componentDidMount() {
		await worker.initialize();
		if(worker.initialize) {
			this.analyzeContent();
		}
	}

	componentDidUpdate() {
		this.analyzeContent();
	}

	render() {
		const { result, loading } = this.state;
		
		return (
			<div style={{ ...styles.root }}>
				<div
					style={{ ...styles.fieldTitle }}
				>
					<FieldTitle
						label="Content Analysis"
					/>
					
				</div>
				{ loading ? <CircularProgress /> : (
					result && result.seo && result.readability ? 
						<Results
							seo={result.seo[""]}
							readability={result.readability}
							activeMarker={result.activeMarker || ""}
						/> : "Need more data to analysis!" )
				}
			</div>
		);
	}
}

const propTypes = {
  paper: PropTypes.shape({}).isRequired,
};

const defaultProps = {
  // width: 600,
};

ContentAnalysis.propTypes = propTypes;
ContentAnalysis.defaultProps = defaultProps;

export default ContentAnalysis;
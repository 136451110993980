import { Grid } from '@material-ui/core';
import { addField } from 'ra-core';
import React, { useEffect, useState } from 'react';
import UploadField from '../../customs/Upload';

const StudentDetailConfig = (props) => {
  const { initValue } = props;

  const [data, setData] = useState(initValue || {});

  useEffect(() => {
    props.input.onChange(data);
  }, [data]);

  const handleBannerImageChange = (value) => {
    setData({
      ...(data || []),
      headerBannerImage: value,
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <UploadField
          input={{
            value: data.headerBannerImage,
          }}
          meta={{}}
          onChange={handleBannerImageChange}
          source='headerBannerImage'
          label='Ảnh banner'
        />
      </Grid>
    </Grid>
  );
};

export default addField(StudentDetailConfig);

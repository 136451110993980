import moment from 'moment';
import React, { cloneElement } from 'react';
import get from 'lodash/get';
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  Filter,
  FormDataConsumer,
  FunctionField,
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
  sanitizeListRestProps,
  AutocompleteInput,
  SaveButton,
  Toolbar,
} from 'react-admin';
import HomePageConfig from '../customs/HomePageConfig';
import overrideConfig from '../environments/override.config';
import BlogPageConfig from '../customs/BlogPageConfig';
import StudentDetailPageConfig from '../customs/StudentDetailPageConfig';
import PollPageConfig from '../customs/PollPageConfig';
import { useRedirectWithMessage } from '../hooks';

const MethodFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
  </Filter>
);
export const pageConfigTypes = {
  HomePageConfigs: 'homePageConfigs',
  BlogPageConfigs: 'blogPageConfigs',
};
const validateUserCreation = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Name cannot be empty';
  }
  if (!values.type) {
    errors.type = 'Type cannot be empty';
  }
  if (values.type === pageConfigTypes.HomePageConfigs) {
    const highlightedStudyPrograms = get(
      values,
      'homePageConfigs.highlightedStudyPrograms',
      [],
    );
    if (highlightedStudyPrograms.length > 0) {
      highlightedStudyPrograms.forEach((studyProgram, index) => {
        if (!studyProgram.name) {
          errors[`${studyProgram}${index}`] =
            'StudyProgram name cannot be empty';
        }
        if (!studyProgram.courseCategoryRef) {
          errors[`${studyProgram}${index}`] =
            'StudyProgram categories cannot be empty';
        }
      });
    }
  }
  return errors;
};

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

export const PageConfigList = (props) => (
  <List {...props} actions={<ListActions />} filters={<MethodFilter />}>
    <Datagrid rowClick='edit'>
      <TextField source='name' label='Title' />
      <FunctionField
        label='Last updated at'
        render={(record) => `${moment(record.updatedAt).format('DD/MM/YYYY')}`}
      />
      <FunctionField
        label='Created at'
        render={(record) => `${moment(record.createdAt).format('DD/MM/YYYY')}`}
      />
    </Datagrid>
  </List>
);

export const PageConfigCreate = (props) => {
  const onSuccess = useRedirectWithMessage({
    message: 'create successfully',
    url: '/pageConfigs',
  });

  const PostCreateToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton
        label='Save'
        transform={(data) => {
          return { ...data };
        }}
        submitOnEnter={false}
      />
    </Toolbar>
  );
  return (
    <Create undoable={false} onSuccess={onSuccess} {...props}>
      <SimpleForm
        validate={validateUserCreation}
        toolbar={<PostCreateToolbar />}
      >
        <TextInput source='name' label='name' />
        <AutocompleteInput
          source='type'
          choices={overrideConfig.pageConfigTypes}
        />
        <FormDataConsumer>
          {(formData) => {
            return (
              <>
                {formData.formData.type === 'homePageConfigs' && (
                  <HomePageConfig
                    initValue={formData.formData.homePageConfigs}
                    source='homePageConfigs'
                  />
                )}
                {formData.formData.type === 'blogPageConfigs' && (
                  <BlogPageConfig
                    initValue={formData.formData.blogPageConfigs}
                    source='blogPageConfigs'
                  />
                )}
                {formData.formData.type === 'studentDetailPageConfigs' && (
                  <StudentDetailPageConfig
                    initValue={formData.formData.studentDetailPageConfigs}
                    source='studentDetailPageConfigs'
                  />
                )}
                {formData.formData.type === 'pollPageConfigs' && (
                  <PollPageConfig
                    initValue={formData.formData.pollPageConfigs}
                    source='pollPageConfigs'
                  />
                )}
              </>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export const PageConfigEdit = (props) => {
  const onSuccess = useRedirectWithMessage({
    message: 'update successfully',
    url: '/pageConfigs',
  });

  return (
    <Edit undoable={false} onSuccess={onSuccess} title='Edit' {...props}>
      <SimpleForm validate={validateUserCreation}>
        <TextInput source='name' label='name' />
        <AutocompleteInput
          source='type'
          disabled
          choices={overrideConfig.pageConfigTypes}
        />
        <FormDataConsumer>
          {(formData) => {
            return (
              <>
                {formData.formData.type === 'homePageConfigs' && (
                  <HomePageConfig
                    initValue={formData.formData.homePageConfigs}
                    source='homePageConfigs'
                  />
                )}
                {formData.formData.type === 'blogPageConfigs' && (
                  <BlogPageConfig
                    initValue={formData.formData.blogPageConfigs}
                    source='blogPageConfigs'
                  />
                )}
                {formData.formData.type === 'studentDetailPageConfigs' && (
                  <StudentDetailPageConfig
                    initValue={formData.formData.studentDetailPageConfigs}
                    source='studentDetailPageConfigs'
                  />
                )}
                {formData.formData.type === 'pollPageConfigs' && (
                  <PollPageConfig
                    initValue={formData.formData.pollPageConfigs}
                    source='pollPageConfigs'
                  />
                )}
              </>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

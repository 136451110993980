import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

// Create a theme instance.
export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#43A047',
    },
    error: {
      main: red.A400,
    },
    success: {
      main: '#43a047',
    },
    warning: {
      main: '#ff9800',
    },
    background: {
      default: '#ffffff',
    },
    
    divider: 'rgba(0, 0, 0, 0.1)',
  },
});

import React, { useCallback, useEffect, useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  ArrayField,
  SingleFieldList,
  ChipField,
  Create,
  Edit,
  TextInput,
  Filter,
  ArrayInput,
  SimpleFormIterator,
  ReferenceArrayInput,
  TabbedForm,
  TabbedFormTabs,
  FormTab,
  SelectInput,
  required,
  AutocompleteArrayInput, AutocompleteInput, ReferenceInput,
  FunctionField, useCreate, useUpdate, useRedirect, useNotify, minLength, maxLength
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import UploadField from '../customs/Upload';
import _ from 'lodash';

const useStyles = makeStyles({
  horzContainter: {
    display: 'flex',
    flexDirection: 'row',
    '& *': {
      marginRight: '1rem',
      flexGrow: 0,
    },
  },
  fullWidth: {
    width: '100%',
  },
  factRows: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '& > *': {
      width: 'calc(25% - 1rem)',
    },
  },
  registerRow: {
    width: '100%',
    display: 'flex',
    '& > *': {
      width: 'calc(25% - 1rem)',
      marginRight: '1rem',
    },
  },
  halfWidth: {
    width: '50%',
  },
});
const MethodFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
  </Filter>
);

const LocalAutocompleteArrayInput = (props) => {
  const { input, choices } = props;
  if (input.value === '') {
    input.value = [];
  } else if (input.value) {
    input.value = [...new Set(input.value.map((el) => el._id || el))];
  }
  return (
    <AutocompleteArrayInput
      {...props}
      options={{ fullWidth: false }}
      choices={choices}
      optionText={(record) => {
        if (record) {
          return `${record.name}`;
        }
      }}
      optionValue='_id'
    />
  );
};

export const StudyProgramList = (props) => {
  return (
    <List {...props} filters={<MethodFilter />}>
      <Datagrid rowClick='edit'>
        <TextField source='title' label='Tên lộ trình' />
        {/* <ArrayField source='topics' label='Lộ trình'>
          <SingleFieldList linkType={false}>
            <ChipField source='title' />
          </SingleFieldList>
        </ArrayField> */}
        <FunctionField label='Lộ trình' render={record => {
          if (Array.isArray(record.topics) && record.topics.length > 0) {
            return (
              <ArrayField source='topics'>
                <SingleFieldList linkType={false}>
                  <ChipField source='title' />
                </SingleFieldList>
              </ArrayField>
            );
          } else if (Array.isArray(record.topicsV2) && record.topicsV2.length > 0) {
            return (
              <ArrayField source='topicsV2'>
                <SingleFieldList linkType={false}>
                  <ChipField source='title' />
                </SingleFieldList>
              </ArrayField>
            );
          } else {
            return null;
          }
        }} />
        <TextField source='description' label='Mô tả' />
        <FunctionField label='Layout' render={(record) => {
          const layoutMatch = listLayout.find((el) => el.id === record.layout);
          return _.get(layoutMatch, 'name', '');
        }} />
      </Datagrid>
    </List>
  )
};
const choices = [
  { id: 'image', name: 'Hiện ảnh' },
  { id: 'video', name: 'Hiện video' },
];
const listLayout = [
  { id: 0, name: 'Layout cũ' },
  { id: 1, name: 'Layout Adult' },
  { id: 2, name: 'Layout K12' },
]
const InnerForm = (props) => {
  const classes = useStyles();
  const [layout, setLayout] = useState(0);
  const [id, setId] = useState(0);

  useEffect(() => {
    if (props.record.layout) {
      setLayout(props.record.layout)
    }
    const editId = _.get(props, 'record._id', 0);
    setId(editId);
  }, [])

  const handleChangeLayout = (e) => {
    setLayout(e.target.value);
  }

  const [create] = useCreate();
  const [update] = useUpdate();
  const redirect = useRedirect();
  const notify = useNotify();
  const saveForm = (values) => {
    let dataSubmit = {};
    switch (values.layout) {
      case 0:
      default:
        dataSubmit = {
          ...values,
          topicsV2: undefined,
          benefit: {
            ...values.benefit,
            contentsV2: undefined
          }
        };
        break;
      case 1:
        dataSubmit = {
          ...values,
          topics: undefined,
          topicsV2: values.topicsV2.map(item => (
            {
              title: item.title,
              description: item.description,
              course: item.course,
              img: item.img,
              modules: undefined
            }
          )),
          benefit: {
            ...values.benefit,
            contentsV2: undefined
          }
        }
        break;
      case 2:
        dataSubmit = {
          ...values,
          topics: undefined,
          benefit: {
            ...values.benefit,
            contents: undefined
          }
        };
        break;
    }

    try {
      const { _id } = dataSubmit;
      if (_id) {
        update('studyPrograms', _id, dataSubmit, undefined, {
          onSuccess: ({ data }) => {
            notify('Updated successfully');
            redirect(`/studyPrograms/${_id}`);
          },
          onFailure: (error) => {
            notify(error.body || error.message, 'error');
          }
        });
      } else {
        create('studyPrograms', dataSubmit, {
          onSuccess: ({ data }) => {
            const id = data.id;
            notify('Created successfully');
            redirect(`/studyPrograms/${id}`);
          },
          onFailure: (error) => {
            notify(error.body || error.message, 'error');
          }
        });
      }
    } catch (error) {
      console.log({ error });
    }
  }

  return (
    <TabbedForm tabs={<TabbedFormTabs scrollable />} {...props} save={saveForm} redirect='edit'>
      <FormTab label='Thông tin cơ bản'>
        <TextInput source='title' label='Tên chương trình học' validate={[required()]} />
        <TextInput multiline source='description' label='Mô tả' />
        <TextInput source='clickToDetailLabel' label='CTA' validate={[required()]} />
        <SelectInput source='layout' choices={listLayout} label='Layout' onChange={handleChangeLayout} disabled={id} validate={[required()]} />
        {layout === 0 && (
          <ArrayInput source='topics' label='Chủ đề' defaultValue={[]} validate={[required()]}>
            <SimpleFormIterator>
              <TextInput source='title' label='Tên chủ đề' validate={[required()]} />
              <TextInput source='description' multiline label='Mô tả chủ đề' />
              <ArrayInput source='modules' label='Học phần' validate={[required()]}>
                <SimpleFormIterator>
                  <TextInput source='title' label='Tên học phần' validate={[required()]} />
                  <TextInput source='description' multiline label='Mô tả học phần' validate={required()} />
                  <ReferenceArrayInput
                    validate={[required()]}
                    source='courseRefs'
                    reference='courses'
                    label='Khoá học'
                  >
                    <LocalAutocompleteArrayInput />
                  </ReferenceArrayInput>
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>
        )}
        {layout === 1 && (
          <ArrayInput source='topicsV2' label='Học phần' validate={[required()]}>
            <SimpleFormIterator>
              <TextInput source='title' label='Tên học phần' validate={[required()]} />
              <TextInput source='description' multiline label='Mô tả học phần' />
              <ReferenceInput
                source='course'
                reference='courses'
                label='Khoá học'
                filterToQuery={(searchText) => ({ q: searchText })}
                validate={[required()]}
              >
                <AutocompleteInput optionText='name' />
              </ReferenceInput>
              <UploadField source='img' label='Ảnh' />
            </SimpleFormIterator>
          </ArrayInput>
        )}
        {layout === 2 && (
          <ArrayInput source='topicsV2' label='Chủ đề' validate={[required(), minLength(2, 'Cần ít nhất 2 chủ đề')]}>
            <SimpleFormIterator>
              <TextInput source='title' label='Tên chủ đề' validate={[required()]} />
              <TextInput source='description' multiline label='Mô tả chủ đề' />
              <UploadField source='img' label='Ảnh' />

              <ArrayInput source='modules' label='Học phần' validate={[required()]}>
                <SimpleFormIterator>
                  <TextInput source='title' label='Tên học phần' validate={[required()]} />
                  <TextInput source='description' multiline label='Mô tả học phần' validate={[required()]} />
                  <UploadField source='img' label='Ảnh' />
                  <TextInput source='sessionNumber' label='Thời gian học' validate={[required(), maxLength(15, 'Tối đa 15 kí tự')]} />
                  <TextInput source='hourNumber' label='Thời gian 1 buổi học' validate={[required(), maxLength(15, 'Tối đa 15 kí tự')]} />
                  <ReferenceInput
                    source='courseRefs'
                    reference='courses'
                    label='Khoá học'
                    validate={[required()]}
                  >
                    <AutocompleteInput optionText='name' />
                  </ReferenceInput>
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>
        )}

      </FormTab>
      <FormTab label='Lợi ích'>
        <SelectInput
          source='benefit.currentResourceDisplay'
          label='Chọn thông tin cần hiện'
          choices={choices}
        />
        <UploadField source='benefit.img' label='Ảnh' />
        <TextInput source='benefit.videoUrl' label='Link youtube video' />
        <TextInput source='benefit.title' label='Tiêu đề' validate={[required()]} />
        {layout !== 2 && (
          <ArrayInput
            source='benefit.contents'
            style={{ width: '100%' }}
            label='Nội dung'
            validate={[required()]}
          >
            <SimpleFormIterator>
              <TextInput
                multiline
                label='Mô tả'
                source=''
                className={classes.fullWidth}
                validate={[required()]}
              />
            </SimpleFormIterator>
          </ArrayInput>
        )}
        {layout === 2 && (
          <ArrayInput
            source='benefit.contentsV2'
            style={{ width: '100%' }}
            label='Nội dung'
            validate={[required(), minLength(3, 'Cần ít nhất 3 nội dung')]}
          >
            <SimpleFormIterator>
              <UploadField source='img' label='Ảnh' />
              <TextInput
                multiline
                label='Mô tả'
                source='description'
                validate={[required()]}
                className={classes.fullWidth}
              />
            </SimpleFormIterator>
          </ArrayInput>
        )}

      </FormTab>
    </TabbedForm>
  );
};

export const StudyProgramCreate = (props) => (
  <Create {...props}>
    <InnerForm {...props} />
  </Create>
);

export const StudyProgramEdit = (props) => (
  <Edit title='Edit' {...props}>
    <InnerForm {...props} />
  </Edit>
);

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { getToken } from '../../helpers';
import { API_URL_ADMIN } from '../../constant/url';

const SingleSelectCourseWithSearch = (props) => {
  const { onChange, value, label } = props;
  const [options, setOptions] = useState([]);
  useEffect(() => {
    handleLoadData('');
  }, []);
  const handleLoadData = async (searchString) => {
    const token = await getToken();
    const response = await fetch(`${API_URL_ADMIN}/courses?q=${searchString}`, {
      method: 'GET',
      headers: {
        'x-auth-token': token,
      },
    });
    const data = await response.json();
    setOptions(data);
  };
  return (
    <Autocomplete
      id={`item-${value && (value._id || value)}`}
      size='small'
      getOptionSelected={(option) => option._id}
      value={value}
      options={options}
      filterOptions={(options) => options}
      getOptionLabel={(option) => option.name}
      onChange={(e, value) => {
        onChange(value);
      }}
      DisableClearable={true}
      onKeyUp={(value) => {
        handleLoadData(value.target.value);
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} variant='outlined' />
      )}
    />
  );
};

export default SingleSelectCourseWithSearch;

import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import inflection from 'inflection';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { LinearProgress, InputLabel } from '@material-ui/core';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { addField } from 'ra-core';
import { FieldTitle } from 'react-admin';

import { tkhttpClient } from '../provider/dataProvider';
import { API_URL_UPLOAD, ROOT_API, ROOT_FRONT_END } from '../constant/url';

export class Upload extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      files: [],
      isUploading: false,
      errMsg: '',
    };
  }

  componentDidMount() {
    const { input } = this.props;
    if (!!input.value && typeof input.value === 'string') {
      const fileName = input.value.split('/')[
        input.value.split('/').length - 1
      ];
      this.handleLoadUrl('files', input.value && input.value.indexOf('http') === -1
        ? `${ROOT_FRONT_END}${input.value}`
        : input.value, fileName);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!!nextProps.fileURL) {
      this.handleResetFiles('files', () => {
        this.handleLoadUrl('files', nextProps.fileURL);
      });
    }
  }

  handleLoadUrl = (field, url, fileName) => {
    let file = {
      name: fileName,
      preview: url,
    };
    let files = this.state[field] || [];
    files.push(file);
    this.setState({ [field]: files, errMsg: '' });
  };

  handleAddFile = (field, file) => {
    let files = this.state[field] || [];
    files.push(file);
    this.setState({ [field]: files, isUploading: true, errMsg: '' });
    this.uploadFile(files);
  };

  handleRemoveFile = (field, index) => {
    let files = this.state[field] || [];
    if (files.length > 0) files.splice(index, 1);
    this.setState({ [field]: files, errMsg: '' });
  };

  handleResetFiles = (field, callback = () => {}) => {
    // Revoke any data URL's in existence.
    let files = this.state[field] || [];
    for (let file in files) {
      if (!file.preview) continue;
      window.URL.revokeObjectURL(file.preview);
    }
    this.setState(
      {
        [field]: this.state[field],
        errMsg: '',
      }
    );
  };

  onDrop = (files) => {
    this.setState({ isUploading: true, errMsg: '' });
    const { input, onChange } = this.props;
    const data = new FormData();
    data.append('file', files[0]);

    const options = {
      method: 'POST',
      body: data,
      headers: new Headers({}),
    };
    tkhttpClient(API_URL_UPLOAD, options)
      .then(({ json }) => {
        if (json && json.link) {
          onChange(json.link);
          input.onChange(json.link);
        }
        this.setState({ isUploading: false, errMsg: '' });
      })
      .catch((err) => {
        console.log(err);
        this.handleRemoveFile('files', 0);
        this.setState({ isUploading: false, files: [] });
      });
  };
  
  render() {
    const { meta, source, input, label } = this.props;
    if (typeof meta === 'undefined') {
      throw new Error(
        "The TextInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details.",
      );
    }

    const { isUploading, errMsg, files = [] } = this.state;
    const title = label
      ? label : inflection.transform(source, ['underscore', 'humanize'])
    const fileSource = typeof input.value === 'string' && input.value.indexOf('http') === -1
      ? `${ROOT_FRONT_END}${input.value}` : input.value;
    return (
      <div
        style={{
          marginTop: '16px',
          marginBottom: '8px',
        }}
      >
        <div
          style={{
            paddingBottom: '10px',
            fontSize: '0.75rem',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            color: 'rgba(0, 0, 0, 0.54)'
          }}
        >
          <FieldTitle
            label={title}
          />
        </div>
        <div style={{
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          width: 'fit-content',
        }}>
          {input.value ? <img
            style={{ minWidth: '100px', maxWidth: '500px' }}
            title={title}
            alt={title}
            src={fileSource}
          /> : ''}
        </div>
        <div className="upload" style={{ padding: '1em' }}>
          {isUploading ? (
            <div style={{ marginLeft: '25px', textAlign: 'center' }}>
              <LinearProgress />
              <p>Uploading...</p>
            </div>
          ) : (
            <Dropzone
              onDrop={this.onDrop}
              accept="image/*"
              maxSize={200000000}
            >
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div
                  {...getRootProps()}
                  className={classNames('dropzone', { 'dropzone--isActive': isDragActive })}
                >
                  <input {...getInputProps()} />
                  {
                    isDragActive
                      ? <p>Drop files here...</p>
                      : <p>Click here to choose file...</p>
                  }
                </div>
              )}
            </Dropzone>
          )}
        </div>
        {errMsg ? (
          <div style={{ marginLeft: '25px' }}>
            <p style={{ color: 'red', fontSize: '16px', fontWeight: '700' }}>
              {errMsg}!!!
            </p>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

Upload.propTypes = {
  className: PropTypes.string,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  meta: PropTypes.object,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  type: PropTypes.string,
};

Upload.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  options: {},
  type: 'text',
};

export default compose(
  addField,
  withStyles({}),
)(Upload);
export const handleComponentChangeValue = (
  allData,
  fieldName,
  value,
  index,
) => {
  let newValue = { ...allData };
  if (newValue.highlightedStudyPrograms[index]) {
    newValue = {
      ...newValue.highlightedStudyPrograms[index],
      [fieldName]: value,
    };
  } else {
    newValue = {
      [fieldName]: value,
    };
  }
  return newValue;
};

import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  Filter,
} from 'react-admin';

const MethodFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const TagList = (props) => (
  <List
    {...props}
    filters={<MethodFilter />}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="slug" />
      <DateField source="createdAt" locales="vi-VN" />
    </Datagrid>
  </List>
);

export const TagCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);

const TagTitle = ({ record }) => {
  return <span>Tag {record ? `"${record.name}"` : ''}</span>;
};

export const TagEdit = (props) => (
  <Edit title={<TagTitle />} {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="slug" />
    </SimpleForm>
  </Edit>
);
import React, { Fragment } from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ImageField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  Filter,
} from 'react-admin';

const MethodFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

const FullName = ({ record }) => `${record.name ? record.name.lastName : ''} ${record.name ? record.name.firstName : ''}`

const EmptyActions = () => (
  <Fragment />
);

export const UserList = (props) => (
  <List {...props}
    bulkActionButtons={<EmptyActions />}
    filters={<MethodFilter />}
  >
    <Datagrid rowClick="edit">
      <FullName label="Name" />
      <TextField source="email" />
      <ImageField source="picture" title="avatar" className="profilePicture" />
      <TextField source="tkvnRole" />
      <DateField source="createdAt" locales="vi-VN" />
    </Datagrid>
  </List>
);

// export const UserCreate = (props) => (
//   <Create {...props}>
//     <SimpleForm>
//       <TextInput source="username" />
//       <TextInput source="name" />
//       <TextInput source="password" type="password" autoComplete="new-password" />
//       <SelectInput source="role" choices={[
//         { id: 'user', name: 'User' },
//         { id: 'content-editor', name: 'Content Editor' },
//         { id: 'content-manager', name: 'Content Manager' },
//         { id: 'admin', name: 'Administrator' },
//       ]}/>
//     </SimpleForm>
//   </Create>
// );

const UserTitle = ({ record }) => {
  return <span>User {record ? `"${record.name}"` : ''}</span>;
};

export const UserEdit = (props) => (
  <Edit title={<UserTitle />} {...props}>
    <SimpleForm>
      <TextInput disabled source="username" />
      <TextInput disabled source="name.firstName" />
      <TextInput disabled source="name.lastName" />
      <TextInput disabled source="email" />
      <SelectInput source="tkvnRole" choices={[
        { id: 'guest', name: 'Guest' },
        { id: 'content-editor', name: 'Content Editor' },
        { id: 'marketing', name: 'Marketing' },
        { id: 'moderator', name: 'Moderator' },
        { id: 'admin', name: 'Admin' },
      ]}/>
    </SimpleForm>
  </Edit>
);
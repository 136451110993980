import { useNotify, useRefresh, useRedirect } from 'react-admin';

export const useRedirectWithMessage = ({ url, message }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const redirectWithMessage = () => {
    message && notify(message);
    url && redirect(url);
    refresh();
  };
  return redirectWithMessage;
};

import React from 'react';

import {
  List,
  Datagrid,
  BooleanField,
  Edit,
  TabbedForm,
  TabbedFormTabs,
  FormTab,
  BooleanInput,
  DateField,
} from 'react-admin';

export const SettingsList = (props) => (
  <List {...props}>
    <Datagrid rowClick='edit'>
      <BooleanField source='course.hideClassStudentCount' label='Ẩn lịch học' />
      <DateField source='updatedAt' label='Ngày cập nhật' />
      <DateField source='createdAt' label='Ngày tạo' />
    </Datagrid>
  </List>
);

export const SettingsEdit = (props) => (
  <Edit title='Edit' {...props}>
    <TabbedForm tabs={<TabbedFormTabs scrollable />}>
      <FormTab label='Lớp học'>
        <BooleanInput source='course.hideClassStudentCount' label='Ẩn lịch học' />
      </FormTab>
    </TabbedForm>
  </Edit>
);

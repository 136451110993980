import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  TextInput,
  SimpleForm,
  AutocompleteArrayInput,
  DateTimeInput,
  DateField,
  ReferenceArrayInput,
  Filter,
} from 'react-admin';

const MethodFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

const FullName = props => (`${props.name ? props.name.lastName : ''} ${props.name ? props.name.firstName : ''}`);


export const MediaAppearancesList = props => (
  <List
    {...props}
    filters={<MethodFilter />}
  >
    <Datagrid rowClick="edit">
      <TextField source="title" label="Tiêu đề" />
      <TextField source="chanel" label="Kênh/Đơn vị" />
      <TextField source="link" label="Url nhúng(embed) video" />
      <DateField source="date" label="Ngày phát sóng" locales="vi-VN" />
    </Datagrid>
  </List>
);

export const MediaAppearancesCreate = props => (
 <Create {...props}>
  <SimpleForm redirect="list">
    <TextInput source="title" label="Tiêu đề" />
    <TextInput source="chanel" label="Kênh/Đơn vị" />
    <TextInput source="link" label="Url nhúng(embed) video" />
    <DateTimeInput source="date" label="Ngày phát sóng" locales="vi-VN" />
  </SimpleForm>
 </Create> 
);

export const MediaAppearancesEdit = props => (
  <Edit {...props} title="Edit Media">
   <SimpleForm>
    <TextInput source="title" label="Tiêu đề" />
    <TextInput source="chanel" label="Kênh/Đơn vị" />
    <TextInput source="link" label="Url nhúng(embed) video" />
    <DateTimeInput source="date" label="Ngày phát sóng" locales="vi-VN" />
   </SimpleForm>
  </Edit> 
 );
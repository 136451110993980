import React from 'react';
import PropTypes from 'prop-types';
import { FieldTitle } from 'react-admin';
import Truncate from './Truncate';

const propTypes = {
  title: PropTypes.string.isRequired,
  metaDescription: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  width: PropTypes.number,
};

const defaultProps = {
  width: 600,
};

const styles = {
  root: {
    fontFamily: 'arial,sans-serif',
    width: '592px',
    margin: '16px 0 8px',
  },
  title: {
    color: '#1a0dab',
    fontSize: 22,
    fontWeight: 400,
    lineHeight: 1.2,

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  url: {
    height: 18,
    color: '#006621',
    fontSize: 14,
    lineHeight: '16px',

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  description: {
    color: '#545454',
    fontSize: 'small',
    lineHeight: 1.4,

    wordWrap: 'break-word',
  },
};

function SerpPreview({ title, metaDescription, url, width, ...otherProps }) {
  return (
    <div style={{ ...styles.root, width }} {...otherProps}>
			<div
				style={{
					paddingBottom: '10px',
					fontSize: '0.75rem',
					fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
					color: 'rgba(0, 0, 0, 0.54)'
				}}
			>
				<FieldTitle
					label="Snippet preview"
				/>
				
			</div>
      <Truncate element="div" maxWidth={width} style={styles.title}>
        {title}
      </Truncate>
      <div style={styles.url}>{url}</div>
      <Truncate element="div" maxChars={160} style={styles.description}>
        {metaDescription}
      </Truncate>
    </div>
  );
}

SerpPreview.propTypes = propTypes;
SerpPreview.defaultProps = defaultProps;

export default SerpPreview;

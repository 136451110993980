import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  TextInput,
  SingleFieldList,
  SimpleForm,
  ArrayField,
  ArrayInput,
  SimpleFormIterator,
  ChipField,
  BooleanField,
  BooleanInput,
  Filter,
  ReferenceArrayField,
  FormDataConsumer,
  NumberInput,
  NumberField,
} from 'react-admin';
import UploadField from '../customs/Upload';
import TextEditor from '../customs/TextEditor';
import InputWithSlug from '../customs/InputWithSlug';
import './customizeStyle.css';
import { SingleCourseSelect } from '../customs/SingleCourseSelect';
import MultiCoursesSelect from '../customs/MultiCoursesSelect';

const MethodFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
  </Filter>
);

export const HonoredStudentsList = (props) => (
  <List
    {...props}
    sort={{ field: 'updatedAt', order: 'DESC' }}
    filters={<MethodFilter />}
  >
    <Datagrid rowClick='edit'>
      <TextField source='name' label='Họ tên' />
      <BooleanField source='highlightStudent' label='Học viên nổi bật' />
      <BooleanField source='isActive' label='Đang hoạt động' />
      <NumberField source='sortOrder' label='Thứ tự sắp xếp' />
      <ReferenceArrayField
        label='Khoá học'
        reference='courses'
        source='courseRefs'
      >
        <SingleFieldList>
          <ChipField source='name' />
        </SingleFieldList>
      </ReferenceArrayField>
      <ArrayField source='prize' label='Prize'>
        <SingleFieldList className='PreventAnchorTag'>
          <ChipField source='prize' />
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  </List>
);

const renderFormInner = ({ id, isCreate }) => {
  const statusFieldProps = isCreate ? { defaultValue: true } : {};
  return (
    <SimpleForm redirect='list'>
      <FormDataConsumer>
        {(formData) => {
          return (
            <InputWithSlug
              initValue={formData.formData}
              resourceId={id}
              subApi='honoredStudents'
              source='name'
              label='Name'
            />
          );
        }}
      </FormDataConsumer>
      <BooleanInput source='highlightStudent' label='Học viên nổi bật' />
      <BooleanInput
        source='isActive'
        label='Đang hoạt động'
        {...statusFieldProps}
      />
      <NumberInput source='sortOrder' label='Thứ tự sắp xếp' />
      <UploadField source='avatar' label='Ảnh đại diện' />
      <TextInput
        source='videoIntro'
        label='Url nhúng(embed) video giới thiệu'
      />
      <TextInput
        source='shortIntro'
        label='Câu giới thiệu ngắn'
      />
      <MultiCoursesSelect label='Các khóa học' source='courseRefs' />
      <SingleCourseSelect label='Liên kết đến khóa học' source='courseRef' />
      <ArrayInput source='prize'>
        <SimpleFormIterator>
          <TextInput source='prize' />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput multiline source='quote' label='Trích dẫn' />
      <TextEditor source='story' label='Câu truyện' />
    </SimpleForm>
  );
};

export const HonoredStudentsCreate = (props) => {
  return <Create {...props}>{renderFormInner({ id: props.id, isCreate: true })}</Create>;
};

export const HonoredStudentsEdit = (props) => (
  <Edit {...props} title='Edit Students Honored'>
    {renderFormInner({ id: props.id })}
  </Edit>
);

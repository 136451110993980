import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  TextInput,
  SimpleForm,
  ArrayInput,
  SimpleFormIterator,
  Filter,
} from 'react-admin';

import UploadField from '../customs/Upload';

const MethodFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const TechkidsStoriesList = (props) => {
  return (
  <List
    {...props}
    filters={<MethodFilter />}
  >
    <Datagrid rowClick="edit">
      <TextField source="title" label="Tiêu đề" />
    </Datagrid>
  </List>
);
};

export const TechkidsStoriesCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="title" label="Tiêu đề" />
      <UploadField source="image" label="Ảnh" />
      <ArrayInput source="stories" label="Nội dung">
        <SimpleFormIterator>
          <TextInput source="" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);

export const TechkidsStoriesEdit = (props) => (
  <Edit {...props} title="Edit Story">
    <SimpleForm>
      <TextInput source="title" label="Tiêu đề" />
      <UploadField source="image" label="Ảnh" />
      <ArrayInput source="stories" label="Câu truyện">
        <SimpleFormIterator>
          <TextInput source="" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

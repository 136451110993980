import React, { Fragment } from 'react';
import {
  List,
  Show,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  ChipField,
  TextInput,
  Filter,
  SimpleShowLayout,
  AutocompleteInput,
  DateInput,
  RefreshButton,
} from 'react-admin';
import ColorChipField from '../customs/ColorChipField';
import './customizeStyle.css';

const EmptyActions = () => <Fragment />;
const statusChoices = [
  {
    id: 'Waiting',
    display: 'Waiting',
  },
  {
    id: 'Success',
    display: 'Success',
  },
  {
    id: 'Failed',
    display: 'Failed',
  },
  {
    id: 'NotTracked',
    display: 'NotTracked',
  },
];

const MethodFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
    <AutocompleteInput
      source='status'
      choices={statusChoices}
      label='Tình trạng'
      optionText='display'
      allowEmpty={false}
      alwaysOn
      resettable={true}
    />
    <DateInput
      options={{ format: 'DD/MM/YYYY', clearable: true }}
      source='startTime'
      label='Từ ngày'
      alwaysOn
    />
    <DateInput
      options={{ format: 'DD/MM/YYYY' }}
      source='endTime'
      label='Đến ngày'
      alwaysOn
    />
    <RefreshButton
      variant='contained'
      color='primary'
      alwaysOn
      onClick={() => props.setFilters({})}
    />
  </Filter>
);

export const RegistrationList = (props) => (
  <List
    {...props}
    filters={<MethodFilter />}
    sort={{ field: 'updatedAt', order: 'DESC' }}
    bulkActionButtons={<EmptyActions />}
  >
    <Datagrid rowClick='show'>
      <DateField source='createdAt' label='Ngày đăng ký' />
      <ReferenceField source='location' reference='locations' label='Cơ sở'>
        <ChipField source='name' />
      </ReferenceField>
      <TextField source='studentInfo.name' label='Tên ứng viên' />
      <TextField
        source='studentInfo.phoneNumber'
        label='Số điện thoại ứng viên'
      />
      <TextField source='parentInfo.name' label='Tên phụ huynh' />
      <TextField
        source='parentInfo.phoneNumber'
        label='Số điện thoại phụ huynh'
      />
      <ReferenceField source='course' reference='courses' label='Khoá học'>
        <ChipField source='name' />
      </ReferenceField>
      <ColorChipField source='status' label='Tình trạng' />
      <TextField style={{ color:'#b80202' }} source='errorMessage' label='Lỗi nhận được' />
      <TextField source='selectedPrice.title' label='Gói Đăng ký' />
    </Datagrid>
  </List>
);

export const RegistrationShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source='studentInfo.name' label='Tên ứng viên' />
      <TextField
        source='studentInfo.phoneNumber'
        label='Số điện thoại ứng viên'
      />
      <TextField source='studentInfo.school' label='Trường học' />
      <TextField source='parentInfo.name' label='Tên phụ huynh' />
      <TextField
        source='parentInfo.phoneNumber'
        label='Số điện thoại phụ huynh'
      />
      <TextField source='additionalInfo.email' label='Email' />
      <ReferenceField source='location' reference='locations' label='Cơ sở'>
        <ChipField source='name' />
      </ReferenceField>
      <ReferenceField source='course' reference='courses' label='Khoá học'>
        <ChipField source='name' />
      </ReferenceField>
      <TextField source='selectedPrice.title' label='Gói Đăng ký' />
      <ColorChipField source='status' label='Tình trạng' />
      <TextField style={{ color:'#b80202' }} source='errorMessage' label='Lỗi nhận được' />
      <TextField source='selectedPrice.price.total' label='Tổng giá' />
    </SimpleShowLayout>
  </Show>
);

import React from 'react';
import {
  List,
  Datagrid,
  Create,
  Edit,
  SimpleForm,
  RichTextField,
} from 'react-admin';

import TextEditor from '../customs/TextEditor';
import UploadField from '../customs/Upload';

export const HomeSlideList = (props) => (
  <List {...props}>
    <Datagrid rowClick='edit'>
      <RichTextField source='content' />
    </Datagrid>
  </List>
);

export const HomeSlideCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <UploadField source='image' label='Ảnh' />
      <TextEditor source='content' label='Nội dung' />
    </SimpleForm>
  </Create>
);

export const HomeSlideEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <UploadField source='image' label='Ảnh' />
      <TextEditor source='content' label='Nội dung' />
    </SimpleForm>
  </Edit>
);

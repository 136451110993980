import React, { Fragment } from 'react';
import {
    List,
    required,
    Datagrid,
    Create,
    SimpleForm,
    DateTimeInput,
    TextInput,
    TextField,
    DateField,
    BooleanField,
    BooleanInput,
    FormTab,
    Filter,
    Edit,
    TabbedForm,
} from 'react-admin';
import UploadField from "../customs/Upload";
const MethodFilter = props => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

export const ContestsList = (props) => {
    return (
        <List {...props}

            sort={{ field: 'updatedAt', order: 'DESC' }}
            filters={<MethodFilter />}
        >
            <Datagrid rowClick="edit">
                <TextField label="Tên cuộc thi" source="title" />
                <DateField label="Thời gian bắt đầu" source="startDate" locales="vi-VN" />
                <DateField label="Thời gian kết thúc" source="endDate" locales="vi-VN" />
                <BooleanField label="Đang hoạt động" source="isActive" />
            </Datagrid>
        </List>
    )
};

export const ContestsEdit = (props) => {
    return (
        <Edit {...props} title="Edit Contest">
            <TabbedForm>
                <FormTab label="Thông tin cuộc thi">
                    <TextInput source="title" label="Tên cuộc thi" validate={required()}/>
                    <UploadField source="coverImage" label="Ảnh đại diện" />
                    <DateTimeInput label="Thời gian bắt đầu" source="startDate" locales="vi-VN" />
                    <DateTimeInput label="Thời gian kết thúc" source="endDate" locales="vi-VN" />
                    <BooleanInput label="Đang hoạt động" source="isActive" />
                </FormTab>
            </TabbedForm>

        </Edit>
    );
}

export const ContestsCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="title" placeholder="Tên cuộc thi" validate={required()}/>
            <UploadField source="coverImage" label="Ảnh đại diện" />
            <DateTimeInput source="startDate" label="Thời gian bắt đầu" />
            <DateTimeInput source="endDate" label="Thời gian kết thúc" />
            <BooleanInput label="Đang hoạt động" source="isActive" initialValue={true} />
        </SimpleForm>
    </Create>
);

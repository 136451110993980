import { TextField } from '@material-ui/core';
import { addField } from 'ra-core';
import React, { useEffect } from 'react';

const CustomInput = (props) => {
  const { currentValue, error, handleOnChange, label } = props;

  const handleValueChange = (e) => {
    const data = e.target.value;
    handleOnChange(data);
  };
  useEffect(() => {
    props.input.onChange(currentValue);
  }, [currentValue]);

  return (
    <>
      <TextField
        margin='normal'
        size='small'
        onChange={handleValueChange}
        error={Boolean(error)}
        id='filled-basic'
        label={label}
        variant='filled'
        helperText={error}
        value={currentValue || ''}
      />
    </>
  );
};

export default addField(CustomInput);
